import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
} from "react-router-dom";
import { appState } from "../../appState";
import {format_ts, t} from "../../localization";

export async function loader({ request }) {
    return {  };
}

function stampRand() {
    const maxOffset = 8;
    const top = 4 + maxOffset - Math.random() * maxOffset*2
    const left = 4 + maxOffset - Math.random() * maxOffset*2
    const angle = 45 - Math.random() * 90

    return {top: top+"%", left: left+"%", transform: "rotate("+angle+"deg)"}
}

export default function StampList(args) {
    var stampHoles = args.stamps.map((stamp) => (
        {
            "key": stamp.id,
            "stamp": stamp,
        }
    ));

    let minHoles = 5*5
    if (stampHoles.length < minHoles) {
        for (let i = stampHoles.length; i < minHoles; i++) {
            stampHoles.push({
                "key": "hole"+i,
                "hole": true
            })
        }
    } else {
        for (let i = (stampHoles.length % 5); i < 5; i++) {
            stampHoles.push({
                "key": "hole"+i,
                "hole": true
            })
        }
    }

    return (
        <>
            <div>
            {stampHoles.map((stampHole) => (
                <div className="stampHole" key={stampHole.key}>
                    <div className="dotted"></div>
                    { stampHole.stamp != undefined ?
                    <div className={"stamp shop" + stampHole.stamp.shop_id} style={stampRand()}>
                        <span className="number">{stampHole.stamp.mission_id != null ? <span>{appState.missionLookup.get(stampHole.stamp.mission_id).number}</span> : ""}</span>
                        <span className="shop">{appState.shopLookup.get(stampHole.stamp.shop_id).initial}</span>
                        <span className="date">{stampHole.stamp.display_date}</span>
                    </div>
                        : ""
                    }
                </div>
            ))}
            </div>
        </>
    );
}
