import {appState, setSessionId} from "./appState";

export class Server {
    // static baseUrl = window.location.hostname == "localhost" ? "http://localhost:3001/api" : "http://192.168.29.170:3001/api"
   static baseUrl = window.location.hostname == "localhost" ? "http://localhost:3001/api" : "https://api.astarostamp.com"
    static client = "web"

    async request(url, method, request) {
        var args = {
            method: method,
            mode: 'cors',
            cache: "reload",
        }

        if (method != "GET") {

            if (request instanceof FormData) {
                args["body"] = request
            } else {
                args["headers"] = {
                    "Content-Type": "application/json",
                }

                args["body"] = JSON.stringify(request)
            }
        }

        const fetchResponse = await fetch(Server.baseUrl + url, args);

        const response = await fetchResponse.json();
        console.log(response);
        return response
    }

    async fetch_common() {
        const response = await this.request("/common", "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.missions == undefined) { throw "error-genericNetworkError" }
        if (response.shops == undefined) { throw "error-genericNetworkError" }

        appState.missions = response.missions
        appState.shops = response.shops
    }

    async new_user(user_info) {
        user_info.client = Server.client;
        let request = {
            user: user_info
        }

        const response = await this.request("/users", "POST", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.user == undefined) { throw "error-genericNetworkError" }
        if (response.session == undefined) { throw "error-genericNetworkError" }

        appState.user = response.user;
        appState.sessionid = response.session.reference_key;

        return response.session;
    }

    async fetch_user() {
        if (!appState.sessionid) { return }

        const response = await this.request("/users/me?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.user == undefined) { throw "error-genericNetworkError" }

        appState.user = response.user

        return response.user;
    }

    //register_user not supported

    async login_user(email, password) {
        let request = {
            user: {
                email: email,
                password: password,
                client: Server.client,
            }
        }

        const response = await this.request("/sessions", "POST", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.user == undefined) { throw "error-genericNetworkError" }
        if (response.session == undefined) { throw "error-genericNetworkError" }

        appState.user = response.user;
        appState.sessionid = response.session.reference_key;

        return response.user;
    }

    async edit_user(user_info) {
        let request = {
            user: user_info
        }

        const response = await this.request("/users/"+appState.user.id+"?session="+appState.sessionid, "PATCH", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.user == undefined) { throw "error-genericNetworkError" }

        return response.user;
    }

    //user_push_token not supported

    async poll_stamp() {
        if (!appState.sessionid) { return }

        const response = await this.request("/users/me?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.user == undefined) { throw "error-genericNetworkError" }

        if (appState.user.stamps.length != response.user.stamps.length) {
            appState.user = response.user;
            if (appState.user.stamps[appState.user.stamps.length-1].mission_id == null) {
                return 'basic';
            } else {
                return 'mission';
            }
        }

        if (appState.user.rally_cards.length != response.user.rally_cards.length) {
            appState.user = response.user;
            return 'rally';
        }

        if (appState.user.issued_coupons.length != response.user.issued_coupons.length) {
            appState.user = response.user;
            return 'coupon';
        }

        return false;
    }

    async admin_edit_mission(mission_id, mission_info) {
       if (mission_info.limited_shopid === "0") {
           mission_info.limited_shopid = null;
       }

        let request = {
            mission: mission_info
        }

        const response = await this.request("/missions/"+mission_id+"?session="+appState.sessionid, "PATCH", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.mission == undefined) { throw "error-genericNetworkError" }

        return response.mission;
    }

    async admin_new_mission(mission_info) {
        let request = {
            mission: mission_info
        }

        const response = await this.request("/missions?session="+appState.sessionid, "POST", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.mission == undefined) { throw "error-genericNetworkError" }

        return response.mission;
    }

    async admin_get_users() {
        const response = await this.request("/users?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.users == undefined) { throw "error-genericNetworkError" }

        return response.users;
    }

    async admin_get_users_vip() {
        const response = await this.request("/users?is_vip=1&session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.users == undefined) { throw "error-genericNetworkError" }

        return response.users;
    }

    async admin_get_user(user_id) {
        const response = await this.request("/users/"+user_id+"?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.user == undefined) { throw "error-genericNetworkError" }

        return response.user;
    }

    async admin_edit_user(user_id, user_info) {
        let request = {
            user: user_info
        }

        const response = await this.request("/users/"+user_id+"?session="+appState.sessionid, "PATCH", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.user == undefined) { throw "error-genericNetworkError" }

        return response.user;
    }

    async admin_get_recents() {
        const response = await this.request("/recents?session="+appState.sessionid, "GET")

        console.log(response);

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.users == undefined) { throw "error-genericNetworkError" }

        return response;
    }
    async admin_get_stamps() {
        const response = await this.request("/stamps?session="+appState.sessionid, "GET")

        console.log(response);

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.stamps == undefined) { throw "error-genericNetworkError" }

        return response;
    }
    async admin_get_transactions() {
        const response = await this.request("/admin/transactions?session="+appState.sessionid, "GET")

        console.log(response);

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.transactions == undefined) { throw "error-genericNetworkError" }

        return response;
    }

    async admin_get_felica_cards() {
        const response = await this.request("/cards?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.cards == undefined) { throw "error-genericNetworkError" }

        return response.cards;
    }

    async admin_get_rally_cards() {
        const response = await this.request("/rally_cards?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.rally_cards == undefined) { throw "error-genericNetworkError" }

        return response.rally_cards;
    }

    async admin_get_issued_coupons() {
        const response = await this.request("/issued_coupons?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.issued_coupons == undefined) { throw "error-genericNetworkError" }

        return response.issued_coupons;
    }

    async admin_get_coupons() {
        const response = await this.request("/coupons?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.coupons == undefined) { throw "error-genericNetworkError" }

        return response.coupons;
    }

    async admin_edit_coupon(coupon_id, coupon_info) {
        let request = {
            coupon: coupon_info
        }

        const response = await this.request("/coupons/"+coupon_id+"?session="+appState.sessionid, "PATCH", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.coupon == undefined) { throw "error-genericNetworkError" }

        return response.coupon;
    }

    async admin_new_coupon(coupon_info) {
        let request = {
            coupon: coupon_info
        }

        const response = await this.request("/coupons?session="+appState.sessionid, "POST", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.coupon == undefined) { throw "error-genericNetworkError" }

        return response.coupon;
    }


    async admin_get_prize_categories() {
        const response = await this.request("/prize_categories?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.prize_categories == undefined) { throw "error-genericNetworkError" }

        return response.prize_categories;
    }
    async admin_get_prize_category(prize_category_id) {
        const response = await this.request("/prize_categories/"+prize_category_id+"?session="+appState.sessionid, "GET")

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.prize_category == undefined) { throw "error-genericNetworkError" }

        return response.prize_category;
    }

    async admin_edit_prize_category(prize_category_id, prize_category_info) {
        let request = {
            prize_category: prize_category_info
        }

        const response = await this.request("/prize_categories/"+prize_category_id+"?session="+appState.sessionid, "PATCH", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.prize_category == undefined) { throw "error-genericNetworkError" }

        return response.prize_category;
    }

    async admin_new_prize_category(prize_category_info) {
        let request = {
            prize_category: prize_category_info
        }

        const response = await this.request("/prize_categories?session="+appState.sessionid, "POST", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.prize_category == undefined) { throw "error-genericNetworkError" }

        return response.prize_category;
    }

    async admin_edit_rarity(prize_category_id, rarity_id, rarity_info) {
        let request = {
            rarity: rarity_info
        }

        const response = await this.request("/prize_categories/"+prize_category_id+"/rarities/"+rarity_id+"?session="+appState.sessionid, "PATCH", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.rarity == undefined) { throw "error-genericNetworkError" }

        return response.rarity;
    }

    async admin_new_rarity(prize_category_id, rarity_info) {
        let request = {
            rarity: rarity_info
        }

        const response = await this.request("/prize_categories/"+prize_category_id+"/rarities?session="+appState.sessionid, "POST", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.rarity == undefined) { throw "error-genericNetworkError" }

        return response.rarity;
    }
    async admin_delete_rarity(prize_category_id, rarity_id) {
        const response = await this.request("/prize_categories/"+prize_category_id+"/rarities/"+rarity_id+"?session="+appState.sessionid, "DELETE")

        if (response.errors != undefined) { throw parseErrors(response.errors) }

        return true;
    }

    async admin_edit_prize(prize_category_id, prize_id, prize_info) {
        let request = {
            prize: prize_info
        }

        const response = await this.request("/prize_categories/"+prize_category_id+"/prizes/"+prize_id+"?session="+appState.sessionid, "PATCH", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.prize == undefined) { throw "error-genericNetworkError" }

        return response.prize;
    }

    async admin_new_prize(prize_category_id, prize_info) {
        let request = {
            prize: prize_info
        }

        const response = await this.request("/prize_categories/"+prize_category_id+"/prizes?session="+appState.sessionid, "POST", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.prize == undefined) { throw "error-genericNetworkError" }

        return response.prize;
    }
    async admin_new_prize_with_image(prize_category_id, prize_info, image_file) {
        let request = new FormData();
        request.append("prize[image_file]", image_file);
        request.append("prize[title]", prize_info.title);
        request.append("prize[is_active]", prize_info.is_active);
        request.append("prize[rarity_id]", prize_info.rarity_id);
        request.append("prize[prize_type]", prize_info.prize_type);

        const response = await this.request("/prize_categories/"+prize_category_id+"/prizes?session="+appState.sessionid, "POST", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.prize == undefined) { throw "error-genericNetworkError" }

        return response.prize;
    }
    async admin_delete_prize(prize_category_id, prize_id) {
        const response = await this.request("/prize_categories/"+prize_category_id+"/prizes/"+prize_id+"?session="+appState.sessionid, "DELETE")

        if (response.errors != undefined) { throw parseErrors(response.errors) }

        return true;
    }

    async admin_prize_image(prize_category_id, prize_id, image_file) {
        let request = new FormData();
        request.append("prize[image_file]", image_file);

        const response = await this.request("/prize_categories/"+prize_category_id+"/prizes/"+prize_id+"?session="+appState.sessionid, "PATCH", request)

        if (response.errors != undefined) { throw parseErrors(response.errors) }
        if (response.prize == undefined) { throw "error-genericNetworkError" }

        return response.prize;
    }

}

function parseErrors(errors) {
    const error = errorFromArray(errors);

    const directStrings = ["unknownError", "genericNetworkError", "genericInputError", "invalidSession", "nicknameTooShort", "nicknameTaken", "emailInvalid", "emailTaken", "passwordTooShort", "notEnoughPoints", "unavailableMission", "loginCredentialsIncorrect", "userNotFound"];
    if (directStrings.includes(error.value)) { return "error-" + error.value; }

    if ( error.value == "notLoggedIn" ) { return "error-invalidSession"; }

    const impossibleErrors = ["notAuthorized", "notAuthorized"];
    if (impossibleErrors.includes(error.value)) { return "error-genericInputError"; }

    switch (error.value) {
        case "not logged in":
            switch (error.key) {
                case "auth":
                    return "error-invalidSession";
            }
            break;

        case "can't be blank":
            switch (error.key) {
                case "name":
                    return "error-nicknameTooShort";
                case "password":
                    return "error-passwordTooShort";
            }
            break;

        case "has already been taken":
            switch (error.key) {
                case "name":
                    return "error-nicknameTaken";
                case "email":
                    return "error-emailTaken";
            }
            break;

        case "is invalid":
            switch (error.key) {
                case "email":
                    return "error-emailInvalid";
            }
            break;

    }

    return "error-unknownError";
}

function errorFromArray(errors) {
    let keys = Object.keys(errors);
    if (keys.length == 0) { return "unknownError"; }

    const firstElement = errors[keys[0]];
    if (firstElement == undefined) { return "unknownError"; }

    const firstError = firstElement[0];
    if (firstError == undefined) { return "unknownError"; }

    return { key: keys[0], value: firstError };
}

export let server = new Server()