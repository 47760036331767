import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, useNavigate,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import {t} from "../../localization";

export async function loader({ params }) {
    try {
        await server.fetch_common()
        let user_id = parseInt(params.userId);
        let user = await server.admin_get_user(user_id)
        return {user};
    } catch (e) {
        alert(t(e));
    }

    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "save") {
        const user_info = {
            is_staff: formEntries.is_staff == 1,
            is_admin: formEntries.is_admin == 1,
            perk_video_eligible: formEntries.perk_video_eligible == 1,
        };

        try {
            await server.admin_edit_user(params.userId, user_info)
            return redirect(`/admin/users/${params.userId}`);
        } catch (e) {
            alert(t(e));
        }

        return null;
    }

    return null
}



export default function AdminUserEdit() {
    const navigate = useNavigate();
    const { user } = useLoaderData();
    return (
        <>
            <section>
                <div className="mission">
                    <h1>User {user.id} {user.name}</h1>
                    <Form method="POST" className="mobilecollapse">
                        <input type="hidden" name="action" value="save" />
                        <table>
                            <tbody>
                            <tr>
                                <td><label>ID</label></td>
                                <td><span>{user.id}</span></td>
                            </tr>
                            <tr>
                                <td><label>{t("isRegistered")}?</label></td>
                                <td><span>{user.is_registered ? "○" : "×"}</span></td>
                            </tr>
                            <tr>
                                <td><label>{t("name")}</label></td>
                                <td><span>{user.name}</span></td>
                            </tr>
                            <tr>
                                <td><label>{t("field-mailmagazine")}?</label></td>
                                <td><span>{user.is_signed_up_for_email ? "○" : "×"}</span></td>
                            </tr>
                            <tr>
                                <td><label>{t("staff")}?</label></td>
                                <td><span><input type="checkbox" name="is_staff" value="1" defaultChecked={user.is_staff == 1} /></span></td>
                            </tr>
                            <tr>
                                <td><label>{t("admin")}?</label></td>
                                <td><span><input type="checkbox" name="is_admin" value="1" defaultChecked={user.is_admin == 1} /></span></td>
                            </tr>
                            <tr>
                                <td><label>{t("perk_video_eligible")}?</label></td>
                                <td><span><input type="checkbox" name="perk_video_eligible" value="1" defaultChecked={user.perk_video_eligible == 1} /></span></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <div><button type="submit">{t("button-save")}</button></div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            {t("button-cancel")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Form>
                </div>
            </section>
        </>
    );
}
