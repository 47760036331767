import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, Outlet, NavLink, useNavigation, Navigate,
} from "react-router-dom";
import { appState } from "../../appState";
import { fetch_user } from "../../server";
import TabBar from "../tabBar";
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}


export default function CustomerHome() {
    var tabs = [
        { url: "/stamps", name: t("tab-stamps"), className: "stamps icon" },
        { url: "/missionstamps", name: t("tab-missions"), className: "missions icon" },
    ];

    if (appState.user.rally_cards != undefined && appState.user.rally_cards.length > 0) {
        tabs.push({url: "/rallies", name: t("tab-rallies"), className: "rallies icon"});
    }

    if (appState.user.issued_coupons != undefined && appState.user.issued_coupons.length > 0) {
        tabs.push({url: "/coupons", name: t("tab-coupons"), className: "coupons icon"});
    }

    if (appState.user.wins != undefined && appState.user.wins.length > 0) {
        tabs.push({ url: "/gacha/wins", name: t("tab-gacha"), className: "gacha icon" });
    }


    tabs.push({ url: "/profile", name: t("tab-profile"), className: "profile icon" });

    return (
        <>
            <div id="customerHome">
                <TabBar className="customer" tabs={ tabs } />

                {window.location.pathname == "/" && (
                    <Navigate to="/stamps" replace={true} />
                )}

                <Outlet />
            </div>
        </>
    );
}
