import Cookies from 'universal-cookie';
import { server } from './server';

const cookies = new Cookies();

class AppState {
    #sessionid = null
    get sessionid() {
        return cookies.get("sessionid")
    }
    set sessionid(newValue) {
        if (newValue == null) {
            cookies.remove("sessionid", { path: '/' } );
        } else {
            const expiry = new Date();
            expiry.setFullYear(expiry.getFullYear() + 4);
            cookies.set('sessionid', newValue, { path: '/', expires: expiry });
        }
    }

    user = null

    get normalStamps() {
        return this.user.stamps.filter(stamp => stamp.mission_id == null)
    }

    get missionStamps() {
        return this.user.stamps.filter(stamp => stamp.mission_id != null)
    }

    get missionStampPoints() {
        let totalPoints = appState.missionStamps.reduce((sum, stamp) => {
            let mission = appState.missions.find(m => m.id === stamp.mission_id);
            if (mission) {
                sum += mission.points;
            }
            return sum;
        }, 0);

        return totalPoints;
    }

    #shops = null
    shopLookup = new Map()
    set shops(newValue) {
        this.#shops = newValue
        if (newValue == null) { return }
        newValue.forEach( shop =>
            this.shopLookup.set(shop.id, shop)
        )
    }
    get shops() {
        return this.#shops;
    }

    #missions = null
    missionLookup = new Map()
    set missions(newValue) {
        this.#missions = newValue
        if (newValue == null) { return }
        newValue.forEach( mission =>
            this.missionLookup.set(mission.id, mission)
        )
    }
    get missions() {
        return this.#missions;
    }

    poll = async function (onChanged) {
        if (appState.user != null && appState.user.is_admin != true) {
            try {
                var result = await server.poll_stamp();
                if (result !== false) {
                    onChanged(result);
                }
            } catch (e) { }
        }
    }
}

export var appState = new AppState()

export function initAppState() {
}

export function logout() {
    appState.sessionid = null;
    appState.user = null;
}