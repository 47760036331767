import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, NavLink,
} from "react-router-dom";
import { appState } from "../../appState";
import { fetch_user } from "../../server";
import StampList from "./stampList";
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}


export default function CustomerMissionStamps() {
    const missionStamps = appState.missionStamps;
    const points = appState.missionStamps.reduce(
        (accumulator, currentValue) => accumulator + appState.missionLookup.get(currentValue.mission_id).points,
        0
    );

    return (
        <>
            <div className="stampList missionStamps">
                <div className="info">
                    <div className="title">{t("astaros-mission")}</div>
                    <div className="points">
                        {points} ポイント<br />
                        <NavLink to="/missions">{t("mission-list")}</NavLink>
                    </div>

                </div>
                <StampList stamps={missionStamps} />
            </div>
        </>
    );
}
