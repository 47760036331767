import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import './index.scss';

import Root, {
    loader as rootLoader,
    action as logoutAction,
} from "./routes/root";

import ErrorPage from "./error-page";

import CustomerStamps from "./routes/customer/stamps";
import CustomerMissionStamps from "./routes/customer/missionStamps";
import CustomerProfile, {
    action as profileAction,
} from "./routes/customer/profile";
import AccountHome, {
    action as accountAction,
} from "./routes/account/account";
import AdminMissions, {
    action as missionsAction,
} from "./routes/admin/missions";
import AdminMissionView, {
    loader as missionViewLoader,
    action as missionViewAction,
} from "./routes/admin/missionView";
import AdminMissionEdit, {
    loader as missionEditLoader,
    action as missionEditAction,
} from "./routes/admin/missionEdit";
import AdminUsers, {
    loader as adminUsersLoader,
} from "./routes/admin/users";
import AdminUserView, {
    loader as adminUserViewLoader,
} from "./routes/admin/userView";
import AdminUserEdit, {
    loader as adminUserEditLoader,
    action as adminUserEditAction,
} from "./routes/admin/userEdit";
import AdminNotifications from "./routes/admin/notifications";
import AccountEmail, {
    action as accountEmailAction
} from "./routes/account/mail";
import AccountPassword, {
    action as accountPasswordAction
} from "./routes/account/password";
import PublicMissions from "./routes/customer/missions";
import AdminRecents, {
    loader as adminRecentsLoader
} from "./routes/admin/recents";
import AdminTransactions, {
    loader as adminTransactionsLoader
} from "./routes/admin/transactions";
import AdminGachaHistory, {
    loader as adminGachaHistoryLoader
} from "./routes/admin/gacha-history";
import AdminStampHistory, {
    loader as adminStampHistoryLoader
} from "./routes/admin/stamp-history";
import CustomerGachaGallery from "./routes/customer/gacha/wins";
import CustomerGachaGalleryItem, {
    loader as winViewLoader
} from "./routes/customer/gacha/win";
import AdminHome from "./routes/admin/home";
import CustomerCoupons from "./routes/customer/coupons";
import CustomerRallyCards from "./routes/customer/rallies";
import AdminFelicaCards, {
    loader as adminCardsLoader
} from "./routes/admin/felica-cards";
import AdminRallyCards, {
    loader as adminRallyCardsLoader
} from "./routes/admin/rally-cards";
import AdminIssuedCoupons, {
    loader as adminIssuedCouponsLoader
} from "./routes/admin/issued-coupons";
import AdminCoupons, {
    loader as adminCouponsLoader,
    action as adminCouponsAction
} from "./routes/admin/coupons";
import AdminCouponEdit, {
    loader as adminCouponEditLoader,
    action as adminCouponEditAction
}  from "./routes/admin/couponEdit";
import AdminPrizeCategoryList, {
    loader as adminPrizeCategoriesLoader,
    action as adminPrizeCategoriesAction
} from "./routes/admin/prize_categories";
import AdminPrizeCategory, {
    loader as adminPrizeCategoryLoader,
    action as adminPrizeCategoryAction
}  from "./routes/admin/prize_category";
import AdminPrize, {
    loader as adminPrizeLoader,
    action as adminPrizeAction
}  from "./routes/admin/prize";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        loader: rootLoader,
        action: logoutAction,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: "account",
                        element: <AccountHome />,
                        loader: null,
                        action: accountAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "account/email",
                        element: <AccountEmail />,
                        loader: null,
                        action: accountEmailAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "account/password",
                        element: <AccountPassword />,
                        loader: null,
                        action: accountPasswordAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "stamps",
                        element: <CustomerStamps />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "missionStamps",
                        element: <CustomerMissionStamps />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "missions",
                        element: <PublicMissions />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "missions/special",
                        element: <PublicMissions />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "profile",
                        element: <CustomerProfile />,
                        loader: null,
                        action: profileAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "gacha/wins",
                        element: <CustomerGachaGallery />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "gacha/wins/:winId",
                        element: <CustomerGachaGalleryItem />,
                        loader: winViewLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "rallies",
                        element: <CustomerRallyCards />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "coupons",
                        element: <CustomerCoupons />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin",
                        element: <AdminHome />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/missions",
                        element: <AdminMissions />,
                        loader: null,
                        action: missionsAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/missions/:missionId",
                        element: <AdminMissionView />,
                        loader: missionViewLoader,
                        action: missionViewAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/missions/:missionId/edit",
                        element: <AdminMissionEdit />,
                        loader: missionEditLoader,
                        action: missionEditAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/users",
                        element: <AdminUsers />,
                        loader: adminUsersLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/users/vip",
                        element: <AdminUsers />,
                        loader: adminUsersLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/users/:userId",
                        element: <AdminUserView />,
                        loader: adminUserViewLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/users/:userId/edit",
                        element: <AdminUserEdit />,
                        loader: adminUserEditLoader,
                        action: adminUserEditAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/notifications",
                        element: <AdminNotifications />,
                        loader: null,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/recents",
                        element: <AdminRecents />,
                        loader: adminRecentsLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/transactions",
                        element: <AdminTransactions />,
                        loader: adminTransactionsLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/gacha-history",
                        element: <AdminGachaHistory />,
                        loader: adminGachaHistoryLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/stamp-history",
                        element: <AdminStampHistory />,
                        loader: adminStampHistoryLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/felica-cards",
                        element: <AdminFelicaCards />,
                        loader: adminCardsLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/rally-cards",
                        element: <AdminRallyCards />,
                        loader: adminRallyCardsLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/issued-coupons",
                        element: <AdminIssuedCoupons />,
                        loader: adminIssuedCouponsLoader,
                        action: null,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/coupons",
                        element: <AdminCoupons />,
                        loader: adminCouponsLoader,
                        action: adminCouponsAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/coupons/:couponId/edit",
                        element: <AdminCouponEdit />,
                        loader: adminCouponEditLoader,
                        action: adminCouponEditAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/prize_categories",
                        element: <AdminPrizeCategoryList />,
                        loader: adminPrizeCategoriesLoader,
                        action: adminPrizeCategoriesAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/prize_categories/:prizeCategoryId",
                        element: <AdminPrizeCategory />,
                        loader: adminPrizeCategoryLoader,
                        action: adminPrizeCategoryAction,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "admin/prize_categories/:prizeCategoryId/prizes/:prizeId",
                        element: <AdminPrize />,
                        loader: adminPrizeLoader,
                        action: adminPrizeAction,
                        errorElement: <ErrorPage />,
                    },
                ]
            }
        ],
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

