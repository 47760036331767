import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, Outlet,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import TabBar from "../tabBar";
import {format_ts, format_ts_admin, t} from "../../localization";

export async function loader({ request }) {
    try {
        await server.fetch_common()
        let recents = await server.admin_get_recents();
        console.log(recents);
        return { recents };
    } catch (e) {
        alert(t(e));
    }

    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}


export default function AdminRecents() {
    const { recents } = useLoaderData();

    console.log(recents);

    return (
        <>
            <section>
                <h2>{t("login")}</h2>
                <table>
                    <thead>
                    <tr>
                        <th>{t("updated_at")}</th>
                        <th>{t("created_at")}</th>
                        <th>{t("client_type")}</th>
                        <th>{t("name")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {recents.sessions.map( (session) => (
                        <tr key={session.id}>
                            <td>{format_ts_admin(session.updated_at, true)}</td>
                            <td>{format_ts_admin(session.created_at, true)}</td>
                            <td>{session.client_type}</td>
                            <td>{session.user_name}</td>
                        </tr>
                    ) )}
                    </tbody>
                </table>

                <h2>{t("signup")}</h2>
                <table>
                    <thead>
                    <tr>
                        <th>{t("updated_at")}</th>
                        <th>{t("name")}</th>
                        <th>{t("normal_stamps_count")}</th>
                        <th>{t("mission_stamps_count")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {recents.users.map( (user) => (
                        <tr key={user.id}>
                            <td>{format_ts_admin(user.created_at, true)}</td>
                            <td>{user.name}</td>
                            <td>{user.normal_stamps_count}</td>
                            <td>{user.mission_stamps_count}</td>
                        </tr>
                    ) )}
                    </tbody>
                </table>

                <h2>{t("money-transactions")}</h2>
                <table style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>{t("created_at")}</th>
                        <th>{t("name")}</th>
                        <th>{t("type")}</th>
                        <th>{t("coins")}</th>
                        <th>{t("yen")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {recents.transactions_money.map( (transaction) => (
                        <tr key={transaction.id}>
                            <td>{format_ts_admin(transaction.created_at, true)}</td>
                            <td>{transaction.user_display_name}</td>
                            <td>{t(transaction.transaction_type)}</td>
                            <td>{transaction.amount_coins}</td>
                            <td>{transaction.amount_transaction == null ? "" : transaction.amount_transaction}</td>
                        </tr>
                    ) )}
                    </tbody>
                </table>


                <h2>{t("gacha-transactions")}</h2>
                <table style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>{t("created_at")}</th>
                        <th>{t("name")}</th>
                        <th>{t("type")}</th>
                        <th>{t("coins")}</th>
                        <th>{t("prize")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {recents.transactions_coins.map( (transaction) => (
                        <tr key={transaction.id}>
                            <td>{format_ts_admin(transaction.created_at, true)}</td>
                            <td>{transaction.user_display_name}</td>
                            <td>{t(transaction.transaction_type)}</td>
                            <td>{transaction.amount_coins}</td>
                            <td style={{textAlign: "right"}}>{transaction.win == null ? "" : transaction.win.prize.rarity.name} <br /> {transaction.win == null ? "" : transaction.win.prize.title}</td>
                        </tr>
                    ) )}
                    </tbody>
                </table>

                <h2>{t("stamps")}</h2>
                <table>
                    <thead>
                    <tr>
                        <th>{t("created_at")}</th>
                        <th>{t("shop")}</th>
                        <th>{t("name")}</th>
                        <th>{t("staff_name")}</th>
                        <th>{t("mission")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {recents.stamps.map( (stamp) => (
                        <tr key={stamp.id}>
                            <td>{format_ts_admin(stamp.created_at, true)}</td>
                            <td>{appState.shopLookup.get(stamp.shop_id).initial}</td>
                            <td>{stamp.user_name}</td>
                            <td>{stamp.staffuser_name}</td>
                            <td>{appState.missionLookup.get(stamp.mission_id) ? appState.missionLookup.get(stamp.mission_id).name : "ご来店"}</td>
                        </tr>
                    ) )}
                    </tbody>
                </table>

            </section>
        </>
    );
}
