import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, useNavigate,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "save") {
        const user_info = {
            email: formEntries.email,
            is_signed_up_for_email: formEntries.is_signed_up_for_email == 1,
        };

        try {
            await server.edit_user(user_info)
            return redirect(`/account`);
        } catch (e) {
            alert(t(e));
        }
    }

    return null
}



export default function AccountEmail() {
    const user = appState.user;
    const navigate = useNavigate();

    return (
        <>
            <section id="accountEmail">
                <Form method="post" className="mobilecollapse">
                    <input type="hidden" name="action" value="save" />
                    <table className="form">
                        <tbody>
                        <tr>
                            <td><label htmlFor="email" >{t("field-email")}</label></td>
                            <td><input id="email" name="email" type="email" defaultValue={user.email}/></td>
                        </tr>
                        <tr>
                            <td><label htmlFor="is_signed_up_for_email">{t("field-mailmagazine")}</label></td>
                            <td><input id="is_signed_up_for_email" name="is_signed_up_for_email" type="checkbox" value="1" defaultChecked={user.is_signed_up_for_email == 1} /></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button type="submit">{t("button-savechanges")}</button>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button type="button" onClick={() => { navigate(-1); }} >{t("button-cancel")}</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Form>
            </section>
        </>
    );
}
