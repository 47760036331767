import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import {t, format_ts, format_ts_admin} from "../../localization";

export async function loader({ params }) {
    try {
        await server.fetch_common()
        let user_id = parseInt(params.userId);
        let user = await server.admin_get_user(user_id)
        let users = await server.admin_get_users()
        return { user, users };
    } catch (e) {
        alert(t(e));
    }

    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    return null
}



export default function AdminUserView() {
    const { user, users } = useLoaderData();

    var usersLookup = new Map();
    users.forEach( user =>
        usersLookup.set(user.id, user)
    )


    return (
        <>
            <section>
                <div className="user">
                    <h1>{user.display_name}</h1>
                    <table>
                        <tbody>
                        <tr>
                            <td><label>ID</label></td>
                            <td><span>{user.id}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("created_at")}</label></td>
                            <td><span>{format_ts(user.created_at, true)}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("updated_at")}</label></td>
                            <td><span>{format_ts(user.updated_at, true)}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("isRegistered")}?</label></td>
                            <td><span>{user.is_registered ? "○" : "×"}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("name")}</label></td>
                            <td><span>{user.name}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("field-mailmagazine")}?</label></td>
                            <td><span>{user.is_signed_up_for_email ? "○" : "×"}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("staff")}?</label></td>
                            <td><span>{user.is_staff ? "○" : "×"}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("admin")}?</label></td>
                            <td><span>{user.is_admin ? "○" : "×"}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("vip")}?</label></td>
                            <td><span>{user.is_vip ? "○" : "×"}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("perk_video_eligible")}?</label></td>
                            <td><span>{user.perk_video_eligible ? "○" : "×"}</span></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <Form action="edit">
                                    <input type="hidden" name="action" value="edit" />
                                    <button type="submit">{t("button-edit")}</button>
                                </Form>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            {user.cards.length == 0 ? <></> : <section>
                <h2>{t("cards")}</h2>
                <table>
                    <thead>
                    <tr>
                        <th>{t("serial")}</th>
                        <th>{t("created_at")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {user.cards.map( (card) => (
                        <tr>
                            <td>{card.serial}</td>
                            <td>{format_ts_admin(card.created_at, true)}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </section> }

            <section>
                <h2>{t("login")}</h2>
                <table>
                    <thead>
                    <tr>
                        <th>{t("updated_at")}</th>
                        <th>{t("created_at")}</th>
                        <th>{t("client_type")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {user.sessions.map( (session) => (
                        <tr>
                            <td>{format_ts_admin(session.updated_at, true)}</td>
                            <td>{format_ts_admin(session.created_at, true)}</td>
                            <td>{session.client_type}</td>
                        </tr>
                    ) )}
                    </tbody>
                </table>
            </section>

            <section>
                <h1>{t("stamps")}</h1>
                <table>
                    <thead>
                    <tr>
                        <th>
                            {t("date")}
                        </th>
                        <th>
                            {t("shop")}
                        </th>
                        <th>
                            {t("staff")}
                        </th>
                        <th>
                            {t("mission")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    { user.stamps.toReversed().map (stamp => (
                        <tr>
                            <td>
                                { format_ts_admin(stamp.created_at, true) }
                            </td>
                            <td>
                                { appState.shopLookup.get(stamp.shop_id).initial }
                            </td>
                            <td>
                                { usersLookup.get(stamp.staffuser_id).name }
                            </td>
                            <td>
                                { stamp.mission_id != null ? appState.missionLookup.get(stamp.mission_id).name : t("normal-point") }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </section>
        </>
    );
}
