import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
} from "react-router-dom";
import { appState } from "../../../appState";
import { fetch_user } from "../../../server";
import QRCode from "react-qr-code";

export async function loader({ params }) {
    let win_id = parseInt(params.winId);
    return { win_id };
}


export default function CustomerGachaGalleryItem() {
    const { win_id } = useLoaderData();
    const win = appState.user.wins.slice().filter( win => parseInt(win.id) == win_id )[0];

    showImage(win.prize.reference, appState.user.name);

    return (
        <>
            <section className="winZoom">
                <div className="image">
                    <div className="title">{win.prize.title}</div>
                    <div className="rarity">{win.prize.rarity.name}</div>
                    <img src="" id="win_image" />
                </div>
            </section>
        </>
    );
}


function showImage(img_url, user_name) {
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.src = img_url + "?crossorigin";

    // Create an off-screen canvas element
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    img.onload = function() {
        // Set canvas dimensions to match the image size
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Add your string
        const fontSize = Math.max(img.width, img.height) * 0.04;
        ctx.font = fontSize + "px Arial";
        ctx.strokeStyle = "white";
        ctx.fillStyle = "black";
        ctx.lineWidth = fontSize / 5;
        ctx.strokeText(user_name, 50, img.height - fontSize * 1);
        ctx.fillText(user_name, 50, img.height - fontSize * 1);

        // Create an on-screen image element to display the result
        // Optionally, add the result image to the DOM
        document.getElementById("win_image").src = canvas.toDataURL('image/jpeg', 1);
    };
}