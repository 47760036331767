import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import {t} from "../../localization";

export async function loader({ params }) {
    try {
        await server.fetch_common()
        let mission_id = parseInt(params.missionId);
        let mission = appState.missionLookup.get(mission_id)
        return {mission};
    } catch (e) {
        alert(t(e));
    }

    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    return null
}



export default function AdminMissionView() {
    const { mission } = useLoaderData();
    return (
        <>
            <section>
                <div className="mission">
                    <h1>{mission.name}</h1>
                    <table>
                        <tbody>
                        <tr>
                            <td><label>ID</label></td>
                            <td><span>{mission.id}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("number")}</label></td>
                            <td><span>{mission.number}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("name")}</label></td>
                            <td><span>{mission.name}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("points")}</label></td>
                            <td><span>{mission.points}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("singleUse")}?</label></td>
                            <td><span>{mission.is_single_use ? "○" : "×"}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("shop")}</label></td>
                            <td><span>{mission.limited_shopid ? appState.shopLookup.get(mission.limited_shopid).name : "∞"}</span></td>
                        </tr>
                        <tr>
                            <td><label>{t("active")}?</label></td>
                            <td><span>{mission.is_active ? "○" : "×"}</span></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <Form action="edit">
                                    <input type="hidden" name="action" value="edit" />
                                    <button type="submit">{t("button-edit")}</button>
                                </Form>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
