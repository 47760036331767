import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, NavLink,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import { fetch_user } from "../../server";
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "logout") {
        await logout();
        return redirect(`/`);
    }

    return null
}



export default function AccountHome() {
    return (
        <>
            <div id="accountHome">
                <section className={"accountOptions"}>
                    <div className="name">{appState.user.name}</div>
                    <NavLink to="/account/email">{t("button-editaccount")}</NavLink><br/>
                    <NavLink to="/account/password">{t("button-changepassword")}</NavLink><br/>
                    <hr/>
                    <Form method="post">
                        <input type="hidden" name="action" value="logout"/>
                        <button type="submit">{t("button-logout")}</button>
                    </Form>
                </section>
            </div>
        </>
    );
}
