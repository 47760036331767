import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
} from "react-router-dom";
import { appState } from "../../appState";
import { fetch_user } from "../../server";
import {format_ts, t} from "../../localization";

export async function loader({ request }) {
    return {  };
}


export default function CustomerCoupons() {
    const issued_coupons = appState.user.issued_coupons;

    return (
        <>
            <section className="coupons">
                <div className="info">
                    <div className="title">{t("tab-coupons")}</div>
                </div>
                <div className="couponsList">
                    { issued_coupons.map( (issued_coupon) => (
                        <div className={"coupon " + (issued_coupon.is_used ? "used" : "") + " " + (issued_coupon.is_valid ? "" : "invalid")} key={issued_coupon.id}>
                            <div className="name">{issued_coupon.coupon.name}</div>
                            <div className="description">{issued_coupon.coupon.description}</div>
                            <div className="validity">{t("use-validity-period")} {format_ts(issued_coupon.valid_start)} 〜 {format_ts(issued_coupon.valid_end)}</div>
                        </div>
                    )) }
                </div>
            </section>
        </>
    );
}
