import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
} from "react-router-dom";
import {useState} from "react";
import { appState } from "../../appState";
import {server} from "../../server";
import {t, format_ts, format_ts_admin, utcToLocal, localToUtc} from "../../localization";

export async function loader({ params }) {
    try {
        let prize_category_id = params.prizeCategoryId;

        let prize_category;

        if (prize_category_id != "new") {
            prize_category = await server.admin_get_prize_category(parseInt(prize_category_id));
        }

        if (prize_category == undefined) {
            prize_category = {
                id: "new",
                rarities: [],
            }
        }

        return { prize_category };
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);
    let prize_category_id = parseInt(params.prizeCategoryId);

    if (formEntries.action == "new_prize") {
        return redirect(`/admin/prize_categories/`+prize_category_id+`/prizes/new`);

    } else if (formEntries.action == "save_prize_category") {
        let prize_category_info = {
            name: formEntries.name,
            price_coins: parseInt(formEntries.price_coins),
            is_active: formEntries.is_active == 1,
            is_vip: formEntries.is_vip == 1,
        };

        if (params.prizeCategoryId == "new") {
            try {
                const result = await server.admin_new_prize_category(prize_category_info)
                console.log(result);
                return redirect(`/admin/prize_categories/`+result.id);
            } catch (e) {
                alert(t(e));
            }

        } else {
            await server.admin_edit_prize_category(params.prizeCategoryId, prize_category_info)
            return redirect(`/admin/prize_categories/`+params.prizeCategoryId);
        }

    } else if (formEntries.action == "save_rarities") {
        let prize_categories = await server.admin_get_prize_categories()
        let prize_category = prize_categories.find(prize_category => prize_category.id === prize_category_id)

        const originalMap = new Map();
        prize_category.rarities.forEach(obj => {
            originalMap.set(obj.id, obj);
        });

// Step 2: Create a set of IDs from the form entries
        const formIds = new Set();
        const formData = {};

        for (var key in formEntries) {
            const value = formEntries[key];

            const [field, id] = key.split(':');
            if (!formData[id]) formData[id] = {};
            formData[id][field] = value;
            formIds.add(id);
        }

// Step 3: Check for changes and deletions
        const changedObjects = [];
        const deletedObjects = [];
        const newObjects = [];

        originalMap.forEach((originalObj, id) => {
            if (formIds.has(id+"")) {
                const formObj = formData[id];
                const changes = {};

                if (originalObj.name !== formObj.name) {
                    changes.name = { from: originalObj.name, to: formObj.name };
                }
                if (originalObj.percent !== parseInt(formObj.percent, 10)/100) {
                    changes.percent = { from: originalObj.percent, to: parseInt(formObj.percent, 10)/100 };
                }
                if (originalObj.color !== formObj.color) {
                    changes.color = { from: originalObj.color, to: formObj.color };
                }
                if (originalObj.is_active !== formObj.is_active) {
                    changes.is_active = { from: originalObj.is_active, to: formObj.is_active };
                }

                if (Object.keys(changes).length > 0) {
                    changedObjects.push({ id, changes });
                }
            } else {
                deletedObjects.push(originalObj);
            }
        });

        Object.keys(formData).forEach(id => {
            if (id.startsWith('new')) {
                const newObj = formData[id];
                newObjects.push({
                    name: newObj.name,
                    percent: parseInt(newObj.percent, 10)/100,
                    color: newObj.color
                });
            }
        });

        console.log('Changed Objects:', changedObjects);
        console.log('Deleted Objects:', deletedObjects);
        console.log('New Objects:', newObjects);

        for (var key in changedObjects) {
            const obj = changedObjects[key];
            const rarity_info = {};

            Object.keys(obj.changes).forEach( name => {
                rarity_info[name] = obj.changes[name].to;
            });

            const result = await server.admin_edit_rarity(prize_category_id, obj.id, rarity_info);
            console.log(result);
        };


        for (var key in deletedObjects) {
            const obj = deletedObjects[key];
            await server.admin_delete_rarity(prize_category_id, obj.id);
        };

        for (var key in newObjects) {
            const obj = newObjects[key];
            const result = await server.admin_new_rarity(prize_category_id, obj);
            console.log(result);
        };


    }


    return null
}


export default function AdminPrizeCategory() {
    const prize_category = useLoaderData().prize_category;
    let isEditingDetailsDefault = prize_category.id == "new";

    const [ isEditingDetails, setIsEditingDetails ] = useState(isEditingDetailsDefault);
    const [ isEditingRarities, setIsEditingRarities ] = useState(false);

    const [ editRarities, setEditRarities ] = useState(JSON.parse(JSON.stringify(prize_category.rarities)));
    const [ newRarities, setNewRarities ] = useState(0);

    if (isEditingRarities == false && editRarities.length == 0 && prize_category.rarities.length > 0) {
        //FIXME: This is a hack for the redirect from new->edit. it should not be needed!
        setEditRarities(JSON.parse(JSON.stringify(prize_category.rarities)));
    }

    const navigate = useNavigate();
    const handleRowClick = (prize_category) => {
        setIsEditingDetails(true);
        //navigate(`/admin/prize_categories/${prize_category.id}/edit`);
    }

    const deleteRarity = (rarity) => {
        console.log("removing "+rarity.id);
        setEditRarities((editRarities.filter(filterRarity => ( rarity.id != filterRarity.id ))))
    }

    const addRarity = () => {
        setEditRarities(editRarities.concat([{
            id: "new" + newRarities,
            name: "",
            percent: 0,
            color: "000000",
        }]));

        setNewRarities(newRarities + 1);
    }

    const modRarity = (rarity, key, value) => {
        rarity[key] = value;
        setEditRarities(editRarities.concat([]));
    }

    return (
        <>
        <section>
            <div className="prizeCategoryEdit">
                { prize_category.id == "new" ? "" :
                <div>
                    <button
                        type="button"
                        onClick={() => {
                            navigate("/admin/prize_categories");
                        }}
                    >
                        {t("button-back")}
                    </button>
                </div>
                }
                <h1>{prize_category.name}</h1>

            { isEditingDetails ?
                        <Form method="POST" className="mobilecollapse" onSubmit={() => {
                            setIsEditingDetails(false);
                        }}>
                    {
                        prize_category.has_wins > 0 ? <p className={"warning"}>{t("warning-edit-prize_category").split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}</p> : ""
                    }
                    
                            <input type="hidden" name="action" value="save_prize_category" />
                            <table>
                                <tbody>
                                <tr>
                                    <td><label>ID</label></td>
                                    <td><span>{prize_category.id}</span></td>
                                </tr>
                                <tr>
                                    <td><label>{t("name")}</label></td>
                                    <td><span><input type="text" name="name" defaultValue={prize_category.name} /></span></td>
                                </tr>
                                <tr>
                                    <td><label>{t("price_coins")}</label></td>
                                    <td><span><input type="number" name="price_coins" min="1" max="1095" defaultValue={prize_category.price_coins == null ? 1000 : prize_category.price_coins} /></span></td>
                                </tr>
                                <tr>
                                    <td><label>{t("is_vip")}</label></td>
                                    <td><span><input type="checkbox" name="is_vip" value="1" defaultChecked={prize_category.is_vip} /></span></td>
                                </tr>
                                <tr>
                                    <td><label>{t("is_active")}</label></td>
                                    <td><span><input type="checkbox" name="is_active" value="1" defaultChecked={prize_category.is_active} /></span></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <div><button type="submit">{t("button-save")}</button></div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (prize_category.id == "new") {
                                                        navigate("/admin/prize_categories");
                                                    } else {
                                                        setIsEditingDetails(false);
                                                    }
                                                }}
                                            >
                                                {t("button-cancel")}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Form>
                :
                        <table>
                            <tbody>
                            <tr>
                                <td><label>ID</label></td>
                                <td>{prize_category.id}</td>
                            </tr>
                            <tr>
                                <td><label>{t("name")}</label></td>
                                <td>{prize_category.name}</td>
                            </tr>
                            <tr>
                                <td><label>{t("price_coins")}</label></td>
                                <td>{prize_category.price_coins}</td>
                            </tr>
                            <tr>
                                <td><label>{t("is_vip")}</label></td>
                                <td>{prize_category.is_vip ? "○" : "×"}</td>
                            </tr>
                            <tr>
                                <td><label>{t("is_active")}</label></td>
                                <td>{prize_category.is_active ? "○" : "×"}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setIsEditingDetails(true);
                                            }}
                                        >
                                            {t("button-edit")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
            }
            </div>
        </section>

            { prize_category.id == "new" ? "" :
            <section>
                <h1>{t("rarities")}</h1>
                <Form method="POST" onSubmit={() => {
                    setIsEditingRarities(false);
                }}>
                    <input type="hidden" name="action" value="save_rarities" />
                <table>
                    <thead>
                    <tr>
                        <td>{t("name")}</td>
                        <td>{t("percent")}</td>
                        <td>{t("sparkle")}</td>
                        <td>{t("prizes")}</td>
                    </tr>
                    </thead>
                    <tbody>
                    { editRarities.map( rarity => (
                        <tr key={"editRarity:"+rarity.id}>
                            { isEditingRarities ? (
                                <>
                                    <td><input type="text" name={"name:"+rarity.id} id={"name:"+rarity.id} value={rarity.name + ""} onChange={ e => ( modRarity(rarity, "name", e.target.value) ) } /></td>
                                    <td><input type="text" name={"percent:"+rarity.id} value={Math.round(rarity.percent*100)} size="3" onChange={ e => ( modRarity(rarity, "percent", e.target.value/100) ) } />%</td>
                                    <td>#<input type="text" name={"color:"+rarity.id} value={rarity.color + ""} size="6" onChange={ e => ( modRarity(rarity, "color", e.target.value) ) } /></td>
                                    <td>{rarity.prizes_count}枚</td>
                                    <td>
                                        { rarity.prizes_count > 0 ? "" :
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    deleteRarity(rarity);
                                                }}
                                            >
                                                {t("delete")}
                                            </button>
                                        }
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{rarity.name}</td>
                                    <td>{Math.round(rarity.percent*100)}%</td>
                                    <td style={{backgroundColor: "#"+rarity.color}}>#{rarity.color}</td>
                                    <td>{rarity.prizes_count}枚</td>
                                </>
                            ) }
                        </tr>
                    ) ) }
                    <tr>
                        <td></td>
                        <td> { Math.round(editRarities.reduce((previousValue, currentValue, currentIndex, array) => {
                            return previousValue + array[currentIndex].percent
                        }, 0) * 100) }%</td>
                    </tr>
                    <>
                        { isEditingRarities ? (
                            <>
                                <tr>
                                    <td>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            addRarity();
                                        }}
                                    >
                                        {t("add")}
                                    </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        {t("rarities-color-help")} <a href="https://www.colordic.org" target="_blank">{t("rarities-color-picker")}</a>
                                    </td>
                                </tr>

                                <tr><td colSpan={4}>
                                    <hr />
                                </td></tr>
                                <tr>
                                    <div><button type="submit">{t("button-save")}</button></div>
                                </tr>

                                <tr>
                                    <td>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setEditRarities(JSON.parse(JSON.stringify(prize_category.rarities)));
                                            setIsEditingRarities(false);
                                        }}
                                    >
                                        {t("button-cancel")}
                                    </button>
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsEditingRarities(true);
                                    }}
                                >
                                    {t("button-edit")}
                                </button>
                                </td>
                            </tr>
                        ) }
                    </>
                    </tbody>
                </table>
                </Form>
            </section>
            }

            { prize_category.id == "new" ? "" :
            <section>
                <h1>{t("prizes")}</h1>
                <Form method="post">
                    <input type="hidden" name="action" value="new_prize" />
                    <button type="submit">{t("button-new")}</button>
                </Form>
                <div className="prizeList">
                    { prize_category.rarities.map( rarity => (
                        <div key={rarity.id}>
                            <h2>{rarity.name}</h2>
                        { prize_category.prizes.filter( prize => ( prize.rarity.id == rarity.id ) ).map( prize => (
                            <a key={prize.id} href={"/admin/prize_categories/" + prize_category.id + "/prizes/" + prize.id }>
                            <div className="prize">
                                <div className="title">{prize.title}</div>
                                <div className={"is_active " + (prize.is_active ? "true" : "false")}>{prize.is_active ? t("is_active_true") : t("is_active_false")}</div>
                                <div className="image"><img src={prize.reference} /></div>
                            </div>
                            </a>
                        ) ) }
                        </div>
                    ) ) }
                </div>
            </section>
            }
        </>
    );
}
