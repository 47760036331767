import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
} from "react-router-dom";
import { appState } from "../../appState";
import {server} from "../../server";
import {t, format_ts, format_ts_admin} from "../../localization";

export async function loader({ request }) {
    try {
        let issued_coupons = await server.admin_get_issued_coupons()
        return { issued_coupons };
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}


export default function AdminIssuedCoupons() {
    const issued_coupons = useLoaderData().issued_coupons;

    const navigate = useNavigate();
    const handleRowClick = (card) => {
        navigate(`/admin/users/${card.user.id}`);
    }

    return (
        <>
            <section>
                <div className="issuedCouponsList">
                    <table>
                        <thead>
                        <tr>
                            <td>{t("user")}</td>
                            <td>{t("coupon")}</td>
                            <td>{t("is_valid")}</td>
                            <td>{t("is_used")}</td>
                            <td>{t("created_at")}</td>
                        </tr>
                        </thead>
                        <tbody>
                        { issued_coupons.map( issued_coupon => (
                            <tr key={issued_coupon.id} className={(issued_coupon.is_valid ? "coupon_valid" : "coupon_invalid") + " " + (issued_coupon.is_used ? "coupon_used" : "coupon_notused")}  onClick={()=> handleRowClick(issued_coupon)}>
                                <td>{issued_coupon.user.display_name}</td>
                                <td>{issued_coupon.coupon.name}</td>
                                <td>{issued_coupon.is_valid ? "○" : "×"}</td>
                                <td>{issued_coupon.is_used ? "○" : "×"}</td>
                                <td>{format_ts(issued_coupon.created_at, false, true)}</td>
                            </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
