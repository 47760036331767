import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, NavLink, redirect,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import { fetch_user } from "../../server";
import QRCode from "react-qr-code";
import {format_ts, t} from "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "logout") {
        await logout();
        return redirect(`/`);
    }

    return null
}


export default function CustomerQR() {
    const missionPoints = appState.missionStampPoints;

    return (
        <>
            <section className="qr">
                <div className="membership member">
                    {t("status-registered")}
                </div>

                <div className={"rank " + (missionPoints >= 100 ? "beginner" : "none" )}>
                    <div>
                    <img src="logo192.png" />
                    { missionPoints >= 100 ? t("rank-beginner") : t("until-next-rank-%lld-points").replace("%lld",100-missionPoints) }
                    </div>
                </div>

                <QRCode
                    className="img"
                    size={256}
                    value={appState.user.qr_url}
                    viewBox={`0 0 256 256`}
                />
                <div className="name">
                    {appState.user.display_name}
                </div>
                <table>
                    <tbody>
                    <tr>
                        <td>{t("account-since")}</td>
                        <td>{format_ts(appState.user.created_at, false, true)}</td>
                    </tr>
                    <tr>
                        <td>{t("basic-points")}</td>
                        <td>{appState.normalStamps.length}</td>
                    </tr>
                    <tr>
                        <td>{t("mission-points")}</td>
                        <td>{missionPoints}</td>
                    </tr>
                    {
                        appState.user.wins.length == 0 ? "" :
                            <tr>
                                <td>{t("gacha-times")}</td>
                                <td>{appState.user.wins.length}</td>
                            </tr>

                    }
                    </tbody>
                </table>
            </section>

            <section className={"accountOptions"}>
                <NavLink to="/account/email">{t("button-editaccount")}</NavLink><br/>
                <NavLink to="/account/password">{t("button-changepassword")}</NavLink><br/>
                <hr/>
                <Form method="post">
                    <input type="hidden" name="action" value="logout"/>
                    <button type="submit">{t("button-logout")}</button>
                </Form>
            </section>

        </>
    );
}
