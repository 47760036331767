import {
    Outlet,
    Link,
    NavLink,
    useLoaderData,
    Form,
    redirect,
    useNavigation,
    useSubmit, useFetcher, useNavigate,
} from "react-router-dom";
import { appState, initAppState, logout } from "../appState";
import { server } from "../server";
import AdminFrame from "./admin/frame";
import StaffHome from "./staff/home";
import CustomerHome from "./customer/home";
import NoAccountHome from "./noaccount/home";
import { useInterval } from "../useInterval";
import { t } from '../localization';
import PublicMissions from "./customer/missions";

export async function loader({ request }) {
    initAppState();
    try {
        await server.fetch_common()
    } catch (e) {
        if (appState.missions == undefined && appState.shops == undefined) {
            alert(t(e));
            return {  };
        }
    }

    try {
        await server.fetch_user()
    } catch (e) {
        alert(t(e));
        appState.sessionid = null;
    }

    return {  };
}


export const action = async ({ request }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "logout") {
        await logout();
        return redirect(`/account`);
    }

    if (formEntries.action == "login") {
        try {
            await server.login_user(formEntries.email, formEntries.password)
            return redirect(`/`);
        } catch (e) {
            alert(t(e));
        }
    }

    if (formEntries.action == "signup") {
        if (formEntries.password != formEntries.password_confirm) {
            alert(t("field-password-confirm"));
            return null
        }
        try {
            await server.new_user(formEntries);
            return redirect(`/`);
        } catch (e) {
            alert(t(e));
        }
    }

    return null
}

export default function Root() {
    document.getElementById("loadingApp").style.display = "none";

    const submit = useSubmit();
    const nav = useNavigate();
    const { state } = useNavigation();

    useInterval(() => {
        appState.poll(function (result) {
            var goTo = null;

            switch (result) {
                case 'basic':
                    goTo = "/stamps";
                    break;
                case 'mission':
                    goTo = "/missionstamps";
                    break;
                case "rally":
                    goTo = '/rallies';
                    break;
                case "coupon":
                    goTo = '/coupons';
                    break;
                default:
                    break;
            }

            if (goTo != null) {
                if (window.location.pathname != goTo) {
                    nav(goTo);
                } else {
                    setTimeout(function () {
                        nav(0);
                    }, 500);
                }
            }
        });
    }, 3000);

    return (
        <>
            { state == "submitting" || state == "loading" ? <div id="loadingRoute"><div className="lds-hourglass"></div></div> : ""}
            <div id="container" className={ appState.user != null && appState.user.is_admin == true ? "admin" : "" }>
                {
                    (appState.sessionid == null || appState.user == null) && window.location.pathname.startsWith("/missions") ? <PublicMissions />
                    : appState.sessionid == null || appState.user == null ? <NoAccountHome />
                    : appState.user.is_admin == true ? <AdminFrame />
                    : appState.user.is_staff == true ? <StaffHome />
                    : <CustomerHome />
                }
            </div>
        </>
    );
}