import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
} from "react-router-dom";
import { appState } from "../../appState";
import {server} from "../../server";
import {t, format_ts, format_ts_admin} from "../../localization";

export async function loader({ request }) {
    try {
        let coupons = await server.admin_get_coupons()
        return { coupons };
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "new") {
        return redirect(`/admin/coupons/new/edit`);
    }

    return null
}


export default function AdminCoupons() {
    const coupons = useLoaderData().coupons;

    const navigate = useNavigate();
    const handleRowClick = (coupon) => {
        navigate(`/admin/coupons/${coupon.id}/edit`);
    }

    return (
        <>
            <section>
                <Form method="post">
                    <input type="hidden" name="action" value="new" />
                    <button type="submit">{t("button-new")}</button>
                </Form>
            </section>

            <section>
                <div className="couponsList">
                    <table>
                        <thead>
                        <tr>
                            <td>{t("name")}</td>
                            <td>{t("is_auto")}</td>
                            <td>{t("is_manual")}</td>
                            <td>{t("issued_count")}</td>
                        </tr>
                        </thead>
                        <tbody>
                        { coupons.map( coupon => (
                            <tr key={coupon.id} onClick={()=> handleRowClick(coupon)}>
                                <td>{coupon.name}<br/>
                                    <span className={"description"}>{coupon.description}</span>
                                </td>
                                <td>
                                    {coupon.auto_distribute_start != null ? "○" : "×"}<br />
                                    {coupon.auto_distribute_vip_only == 1 ? "(VIP)" : ""}
                                </td>
                                <td>
                                    {coupon.manual_distribute_validity_days != null ? "○" : "×"}<br />
                                    {coupon.manual_distribute_validity_days != null ? coupon.manual_distribute_validity_days + t("days") : ""}
                                </td>
                                <td>
                                    {coupon.issued_count}
                                </td>
                            </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
