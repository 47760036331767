import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect, NavLink,
} from "react-router-dom";
import { appState } from "../../appState";
import { t } from  "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "new") {
        return redirect(`/admin/missions/new/edit`);
    }

    return null
}


export default function AdminHome() {
    /*
                    <li><NavLink to="/admin/notifications">{t("tab-notifications")}</NavLink></li>
     */

    return (
        <>
            <section className="adminHome">
                <ul>
                    <li><b>Administration</b></li>
                    <li><NavLink to="/admin/missions">{t("tab-missions")}</NavLink></li>
                    <li><NavLink to="/admin/users">{t("tab-users")}</NavLink></li>
                    <li><NavLink to="/admin/coupons">{t("coupons")}</NavLink></li>
                    <li><NavLink to="/admin/prize_categories">{t("gacha")}</NavLink></li>
                    <li><b>Status</b></li>
                    <li><NavLink to="/admin/users/vip">{t("users-vip")}</NavLink></li>
                    <li><NavLink to="/admin/stamp-history">{t("stamp-history")}</NavLink></li>
                    <li><NavLink to="/admin/gacha-history">{t("gacha-history")}</NavLink></li>
                    <li><NavLink to="/admin/transactions">{t("transactions-history")}</NavLink></li>
                    <li><NavLink to="/admin/felica-cards">{t("felica-cards")}</NavLink></li>
                    <li><NavLink to="/admin/rally-cards">{t("rally-cards")}</NavLink></li>
                    <li><NavLink to="/admin/issued-coupons">{t("issued-coupons")}</NavLink></li>
                    <li><NavLink to="/admin/recents">{t("recents")}</NavLink></li>
                    <hr />
                    <li><NavLink to="/account">{t("tab-account")}</NavLink></li>
                </ul>
            </section>

        </>
    );
}
