import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
} from "react-router-dom";
import { appState } from "../../appState";
import {server} from "../../server";
import {t, format_ts, format_ts_admin} from "../../localization";

export async function loader({ request }) {
    try {
        const url = new URL(request.url);
        if (url.pathname == "/admin/users/vip") {
            let x = await server.admin_get_users_vip()
            let users = await server.admin_get_users_vip()
            return { users };
        } else {
            let users = await server.admin_get_users()
            return { users };
        }
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}


export default function AdminUsers() {
    const users = useLoaderData().users;

    console.log(users);

    const navigate = useNavigate();
    const handleRowClick = (user) => {
        navigate(`/admin/users/${user.id}`);
    }

    return (
        <>
            <section>
                <div className="missionList">
                    <table>
                        <thead>
                        <tr>
                            <td>{t("isRegistered")}?</td>
                            <td>{t("name")}</td>
                            <td>{t("staff")}?</td>
                            <td>{t("admin")}?</td>
                            <td>{t("registered_date")}</td>
                            <td>{t("normal_stamps_count")}</td>
                            <td>{t("mission_stamps_count")}</td>
                        </tr>
                        </thead>
                        <tbody>
                        { users.map( user => (
                            <tr key={user.id}  onClick={()=> handleRowClick(user)}>
                                <td>{user.is_registered ? "○" : "×"}</td>
                                <td>{user.display_name}</td>
                                <td>{user.is_staff ? "○" : "×"}</td>
                                <td>{user.is_admin ? "○" : "×"}</td>
                                <td>{format_ts_admin(user.created_at, true)}</td>
                                <td>{user.normal_stamps_count}</td>
                                <td>{user.mission_stamps_count}</td>
                            </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
