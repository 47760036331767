import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, Outlet,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import TabBar from "../tabBar";
import {format_ts, format_ts_admin, t} from "../../localization";

export async function loader({ request }) {
    try {
        await server.fetch_common()
        let recents = await server.admin_get_stamps();
        console.log(recents);
        return { recents };
    } catch (e) {
        alert(t(e));
    }

    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}


export default function AdminStampHistory() {
    const { recents } = useLoaderData();

    console.log(recents);

    return (
        <>
            <section>
                <h2>{t("stamps")}</h2>
                <table>
                    <thead>
                    <tr>
                        <th>{t("created_at")}</th>
                        <th>{t("shop")}</th>
                        <th>{t("name")}</th>
                        <th>{t("staff_name")}</th>
                        <th>{t("mission")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {recents.stamps.map( (stamp) => (
                        <tr key={stamp.id}>
                            <td>{format_ts_admin(stamp.created_at, true)}</td>
                            <td>{appState.shopLookup.get(stamp.shop_id).initial}</td>
                            <td>{stamp.user_name}</td>
                            <td>{stamp.staffuser_name}</td>
                            <td>{appState.missionLookup.get(stamp.mission_id) ? appState.missionLookup.get(stamp.mission_id).name : "ご来店"}</td>
                        </tr>
                    ) )}
                    </tbody>
                </table>

            </section>
        </>
    );
}
