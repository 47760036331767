import React, { useRef, useState } from 'react';
import { t } from '../localization';

const StyledFileUpload = ({ name = 'file' }) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleButtonClick = (e) => {
    e.preventDefault(); // Extra safety to prevent form submission
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      console.log('Selected file:', file.name);
    }
  };

  // Function to truncate long filenames
  const truncateFilename = (filename) => {
    if (filename.length > 20) {
      const extension = filename.split('.').pop();
      return `${filename.substring(0, 16)}...${extension}`;
    }
    return filename;
  };

  return (
    <div className="space-y-2">
      {/* Hidden native file input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
        accept="*/*"
        name={name}
        id={name}
      />
      
      {/* Button container */}
      <div className="flex flex-col sm:flex-row sm:items-center gap-2">
        {/* Styled button - note the type="button" */}
        <button
          type="button"
          onClick={handleButtonClick}
          className={`
            ${selectedFile ? 'green' : ''}
          `}
        >
          {selectedFile ? truncateFilename(selectedFile.name) : t('button-choose-file')}
        </button>
      </div>
    </div>
  );
};

export default StyledFileUpload;