import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
} from "react-router-dom";
import { appState } from "../../appState";
import { fetch_user } from "../../server";
import StampList from "./stampList"
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}


export default function CustomerStamps() {
    const normalStamps = appState.normalStamps;

    return (
        <>
            <div className="stampList">
                <div className="info">
                    <div className="title">{t("point-card")}</div>
                    <div className="points">{normalStamps.length} ポイント</div>
                </div>
                <StampList stamps={normalStamps} />
            </div>
        </>
    );
}
