import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, NavLink,
} from "react-router-dom";

export async function loader({ request }) {
    return {  };
}


export default function TabBar(args) {
    return (
        <>
            <nav className={args.className + " tabbar"}>
            {args.tabs.map((tab) => (
                    <div key={tab.url} className={ window.location.pathname == tab.url || (!args.isExact && window.location.pathname.startsWith(tab.url)) ? "tab s " + tab.className : "tab " + tab.className }><NavLink to={tab.url}>{tab.name}</NavLink></div>
            ))}
            </nav>
        </>
    );
}
