import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, Outlet, Navigate, NavLink,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import { fetch_user } from "../../server";
import TabBar from "../tabBar";
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "logout") {
        await logout();
        return redirect(`/account`);
    }

    return null
}


export default function AdminFrame() {
    return (
        <>
            {window.location.pathname == "/" && (
                <Navigate to="/admin" replace={true} />
            )}

            {window.location.pathname != "/admin" && (
                <nav id="adminHomeLink">
                    <NavLink to="/admin" className="home icon">{t("menu")}</NavLink>
                </nav>
            )}

            <Outlet />
        </>
    );
}
