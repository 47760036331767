
export function format_ts(ts, long_format, medium_format) {
    let date = new Date(Date.parse(ts));

    if (long_format == true) {
        let mins = date.getMinutes();
        return date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日 " + date.getHours() + ":" + (mins < 10 ? "0" : "") + mins;
    } else if (medium_format == true) {
        return date.getFullYear() + "年" + (date.getMonth()+1) + "月" + date.getDate() + "日";
    } else {
        return (date.getMonth()+1) + "月" + date.getDate() + "日"
    }
}
export function format_ts_admin(ts) {
    let date = new Date(Date.parse(ts));

    let mins = date.getMinutes();
    return (date.getMonth()+1) + "月" + date.getDate() + "日 " + date.getHours() + ":" + (mins < 10 ? "0" : "") + mins;
}

export function utcToLocal(utcDateString) {
    const date = new Date(utcDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Formatted as required by input type="datetime-local"
    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
}

export function localToUtc(localDateString) {
    const date = new Date(localDateString);
    return date.toISOString();
}



export function t(key) {
    const lang = window.navigator.language.startsWith("ja") ? "ja" : "en"
    if (strings[key] == undefined) { return key }
    if (strings[key][lang] == undefined) { return key }
    return strings[key][lang]
}

export const strings= {
    "staff-button-spend30stamps" : {
        "ja" : "使用 - 30印",
        "en" : "Spend 30 Stamps"
    },
    "mission-list" : {
        "ja" : "ミッション一覧",
        "en" : "Missions"
    },
    "error-genericInputError" : {
        "en" : "There was a problem with your input, please check it and try again",
        "ja" : "入力に問題がありました。再度確認して、もう一度お試しください。"
    },
    "error-emailInvalid" : {
        "ja" : "メールアドレスが無効です。確認して再度お試しください。",
        "en" : "The email address is invalid"
    },
    "loading" : {
        "en" : "Loading...",
        "ja" : "ローディング中・・・"
    },
    "error-unknownError" : {
        "en" : "An unknown error has occurred",
        "ja" : "不明なエラーが発生しました。"
    },
    "tab-missions" : {
        "en" : "Missions",
        "ja" : "ミッション"
    },
    "login-why" : {
        "en" : "Or if you already have an account",
        "ja" : "既にアカウントを持ちの方はログインしてください。(2つのアカウントのスタンプは統合できません。)"
    },
    "button-register" : {
        "en" : "Register",
        "ja" : "登録"
    },
    "error-genericNetworkError" : {
        "ja" : "サーバーに接続できませんでした。後でもう一度お試しください。",
        "en" : "Could not connect to the server. Please try again later"
    },
    "tab-specialMissions" : {
        "ja" : "スペシャルミッション",
        "en" : "Special Missions"
    },
    "register-why-required" : {
        "en" : "Register an account and start collecting stamps today!",
        "ja" : "アカウントを登録することで、スタンプの情報を保存することができます。"
    },
    "error-nicknameTaken" : {
        "en" : "That name is already taken, try another one",
        "ja" : "選択したハンドルネームは既に使用されています。別のハンドルネームをお試しください。"
    },
    "error" : {
        "en" : "Error",
        "ja" : "エラー"
    },
    "field-email" : {
        "ja" : "メール",
        "en" : "Email"
    },
    "error-passwordTooShort" : {
        "en" : "That password is too short, try something longer",
        "ja" : "選択したパスワードは短すぎます。もう少し長いものを試してください。"
    },
    "button-login" : {
        "ja" : "ログイン",
        "en" : "Login"
    },
    "button-back" : {
        "en" : "Back",
        "ja" : "戻る"
    },
    "staff-stamp-confirm" : {
        "en" : "Are you sure you want to award this stamp? The customer is ineligible for it.",
        "ja" : "このポイントを付与してもよろしいですか？"
    },
    "error-emailTaken" : {
        "ja" : "このメールアドレスは既に登録されています。すでにアカウントをお持ちの場合は、ログインをお試しください。",
        "en" : "That email address is already registered"
    },
    "staff-stamp-error" : {
        "en" : "And error occurred awarding the stamp.",
        "ja" : "ポイントの付与中にエラーが発生しました。"
    },
    "field-password-confirm" : {
        "en" : "Password (Confirm)",
        "ja" : "パスワード（確認）"
    },
    "button-continue" : {
        "en" : "Continue",
        "ja" : "続く"
    },
    "tab-account" : {
        "en" : "Account",
        "ja" : "アカウント"
    },
    "error-unavailableMission" : {
        "ja" : "このミッションは選択できません。",
        "en" : "That mission is unavailable"
    },
    "error-nicknameTooShort" : {
        "en" : "That name is too short, try something longer",
        "ja" : "選択したハンドルネームは短すぎます。もう少し長いものを試してください。"
    },
    "tab-stamps" : {
        "ja" : "スタンプ",
        "en" : "Stamps"
    },
    "error-notEnoughPoints" : {
        "ja" : "賞品を交換するにはポイントが十分にありません。",
        "en" : "There are not enough points to redeem an award"
    },
    "point-card" : {
        "en" : "Point Card",
        "ja" : "来店ポイントカード"
    },
    "tab-qr" : {
        "en" : "QR",
        "ja" : "QR"
    },
    "button-savechanges" : {
        "ja" : "保存",
        "en" : "Save Changes"
    },
    "field-password" : {
        "ja" : "パスワード",
        "en" : "Password"
    },
    "button-logout" : {
        "en" : "Log out",
        "ja" : "ログアウト"
    },
    "error-session-loggedout" : {
        "ja" : "ログアウトされました。アカウント登録済みの場合は、再度ログインしてください。",
        "en" : "You have been logged out. If you registered your account, please log in again."
    },
    "astaros-mission" : {
        "ja" : "アスタロスミッション",
        "en" : "Mission: Astaros"
    },
    "button-done" : {
        "ja" : "終了",
        "en" : "Done"
    },
    "staff-button-add1stamp" : {
        "ja" : "追加 - １印",
        "en" : "Add 1 Stamp"
    },
    "button-changepassword" : {
        "ja" : "パスワード変更",
        "en" : "Change Password"
    },
    "button-retry" : {
        "ja" : "再試行",
        "en" : "Retry"
    },
    "button-editaccount" : {
        "en" : "Email Settings",
        "ja" : "メール設定"
    },
    "register-why" : {
        "ja" : "アカウントを登録していただくと、iPhoneを紛失した場合やアプリをアンインストールしてもスタンプを復元することができます。",
        "en" : "Register your account so you can recover your stamps when you change phones or you uninstall the app."
    },
    "error-staff-user-not-found" : {
        "ja" : "このQRコードのユーザーは不明です。",
        "en" : "No user was found for this QR code."
    },
    "error-loginCredentialsIncorrect" : {
        "en" : "Your email or password are incorrect. Please check them and try again.",
        "ja" : "メールアドレスまたはパスワードが間違っています。ご確認の上、再度お試しください。"
    },
    "button-cancel" : {
        "en" : "Cancel",
        "ja" : "キャンセル"
    },
    "field-nickname" : {
        "ja" : "ハンドルネーム",
        "en" : "Nickname"
    },
    "staff-stamp-success" : {
        "ja" : "スタンプ成功！",
        "en" : "Stamp awarded successfully!"
    },
    "field-mailmagazine" : {
        "en" : "Mail Magazine",
        "ja" : "メールマガジン"
    },
}

Object.assign(strings, {
    "error-invalidSession" : {
        "ja" : "ログアウトされました。アカウントを登録された場合は、再度ログインしてください。",
        "en" : "You have been logged out. If you registered your account, please log in again."
    },
    "login-why-required" : {
        "en" : "Or if you already have an account",
        "ja" : "既にアカウントを持ちの方はログインしてください。"
    },
    "staff-home": {
        "en" : "Staff functions are only available in the app.",
        "ja" : "スタッフ機能はアプリでしか使えない。"
    },
    "tab-users": {
        "en" : "Users",
        "ja" : "ユーザー"
    },
    "tab-notifications": {
        "en" : "Notifications",
        "ja" : "通知"
    },
    "button-new": {
        "en" : "New",
        "ja" : "追加"
    },
    "button-edit": {
        "en" : "Edit",
        "ja" : "編集"
    },
    "button-save": {
        "en" : "Save",
        "ja" : "保存"
    },
    "number": {
        "en" : "Number",
        "ja" : "番号"
    },
    "name": {
        "en" : "Name",
        "ja" : "名前"
    },
    "points-abbrev": {
        "en" : "Pt",
        "ja" : "ポ"
    },
    "points": {
        "en" : "Points",
        "ja" : "ポイント"
    },
    "singleUse": {
        "en" : "SingleUse",
        "ja" : "一回のみ"
    },
    "shop": {
        "en" : "Shop",
        "ja" : "店舗"
    },
    "active": {
        "en" : "active",
        "ja" : "有効"
    },
    "isRegistered": {
        "en" : "Registered",
        "ja" : "登録"
    },
    "staff": {
        "en" : "Staff",
        "ja" : "スタッフ"
    },
    "admin": {
        "en" : "Admin",
        "ja" : "管理者"
    },
    "registered_date": {
        "en" : "Registered",
        "ja" : "作成日付"
    },
    "normal_stamps_count": {
        "en" : "Stamps",
        "ja" : "来店印"
    },
    "mission_stamps_count": {
        "en": "Mission Stamps",
        "ja": "ミッション印"
    },
    "date": {
        "en" : "Date",
        "ja" : "日付"
    },
    "mission": {
        "en" : "Mission",
        "ja" : "ミッション"
    },
    "normal-point": {
        "en" : "(none)",
        "ja" : "(ご来店)"
    },
    "stamps": {
        "en" : "Stamps",
        "ja" : "スタンプ"
    },
    "signup": {
        "en" : "Sign up",
        "ja" : "登録"
    },
    "login": {
        "en" : "Log in",
        "ja" : "ログイン"
    },

    "until-next-rank-%lld-points": {
        "en": "%lld points until the next rank!",
        "ja": "次のランクまで%lldポイント！",
    },
    "rank-beginner": {
        "en": "Rank: Beginner",
        "ja": "ビギナーランク",
    },
    "status-registered": {
        "en": "Member",
        "ja": "会員",
    },
    "account-since": {
        "en": "Member Since:",
        "ja": "登録日付",
    },
    "basic-points": {
        "en": "Stamps",
        "ja": "ご来店ポイント",
    },
    "mission-points": {
        "en": "Mission Points",
        "ja": "ミッションポイント",
    },
    "gacha-times": {
        "en": "Gacha Spins",
        "ja": "ガチャ回数",
    },

    "spin": {
        "en" : "Spin",
        "ja" : "回す"
    },
    "charge_cash": {
        "en" : "IAP charge",
        "ja" : "入金（App内課金）"
    },
    "charge_inperson": {
        "en" : "Cash charge",
        "ja" : "入金（現金）"
    },
    "charge_renewal": {
        "en" : "VIP renewal",
        "ja" : "VIP入金"
    },
    "money-transactions": {
        "en" : "Money Transactions",
        "ja" : "入金"
    },
    "gacha-transactions": {
        "en" : "Gacha Transactions",
        "ja" : "ガチャ"
    },
    "type": {
        "en" : "Type",
        "ja" : "種類"
    },
    "coins": {
        "en" : "Coins",
        "ja" : "コイン"
    },
    "yen": {
        "en" : "Currency",
        "ja" : "金額"
    },
    "prize": {
        "en" : "Prize",
        "ja" : "景品"
    },

    "recents": {
        "en" : "Recent",
        "ja" : "最近の一見"
    },

    "users-vip": {
        "en" : "VIP users",
        "ja" : "VIP一覧"
    },

    "stamp-history": {
        "en" : "Stamp History",
        "ja" : "スタンプ履歴"
    },
    "gacha-history": {
        "en" : "Gacha History",
        "ja" : "ガチャ履歴"
    },
    "transactions-history": {
        "en" : "Transactions History",
        "ja" : "課金履歴"
    },
    "transactions": {
        "en" : "Transactions",
        "ja" : "課金"
    },
    "perk_video_eligible": {
        "en" : "Eligible for Perk Video",
        "ja" : "VIP特典動画対象"
    },

    "tab-coupons": {
        "en" : "Coupons",
        "ja" : "クーポン箱"
    },
    "tab-stamprally": {
        "en" : "Stamp Rally",
        "ja" : "スタンプラリー"
    },
    "use-validity-period": {
        "en" : "Valid: ",
        "ja" : "ご利用期間："
    },
    "validity-period": {
        "en" : "Entry: ",
        "ja" : "期間："
    },



    "felica-cards": {
        "en" : "FeliCa Cards",
        "ja" : "FeliCaカード一覧"
    },

    "rally-cards": {
        "en" : "Stamp Rally Cards",
        "ja" : "スタンプラリーカード一覧"
    },

    "issued-coupons": {
        "en" : "Coupons",
        "ja" : "クーポン一覧"
    },

    "is_full": {
        "en" : "Full",
        "ja" : "満印"
    },
    "is_used": {
        "en" : "Used",
        "ja" : "使用済み"
    },
    "is_valid": {
        "en" : "Valid",
        "ja" : "使用可能"
    },



    "warning-edit-issued-coupon": {
        "en" : "Warning: This coupon has already been issued to customers. When editing it, you will also edit the information on the issued coupons. Unless you are fixing a typo, it is recommended to set this coupon to Distribution: Disabled and create a new one.",
        "ja" : "⚠️ このクーポンはすでに顧客に発行されています。編集すると、発行済みのクーポンの情報も編集されます。\n\n誤字を修正する場合を除き、このクーポンの発行方法を無効に設定し、新しいクーポンを作成することをお勧めします。"
    },


    "coupons": {
        "en" : "Coupons",
        "ja" : "クーポン"
    },
    "description": {
        "en" : "Description",
        "ja" : "内容"
    },
    "distribute-mode": {
        "en" : "Distribution:",
        "ja" : "発行方法"
    },
    "mode_ignore": {
        "en" : "Disabled",
        "ja" : "無効"
    },
    "mode_manual": {
        "en" : "Manually by staff",
        "ja" : "手動"
    },
    "mode_auto": {
        "en" : "Automatically",
        "ja" : "自動"
    },
    "auto_distribute_start": {
        "en" : "Start date/time",
        "ja" : "自動配る開始日付"
    },
    "auto_distribute_end": {
        "en" : "End date/time",
        "ja" : "自動配る終了日付"
    },
    "auto_distribute_validity_start": {
        "en" : "Start date/time",
        "ja" : "ご利用可能開始日付"
    },
    "auto_distribute_validity_end": {
        "en" : "End date/time",
        "ja" : "ご利用可能終了日付"
    },
    "auto_distribute_vip_only": {
        "en" : "VIP only",
        "ja" : "VIPのみ"
    },
    "manual_distribute_validity_days": {
        "en" : "Coupon validity",
        "ja" : "発行日からの有効期限期間"
    },
    "is_auto": {
        "en" : "Auto?",
        "ja" : "自動発行"
    },
    "is_manual": {
        "en" : "Manual?",
        "ja" : "手動発行"
    },
    "issued_count": {
        "en" : "Coupons",
        "ja" : "発行回数"
    },

    "days": {
        "en" : "days",
        "ja" : "日"
    },

    "gacha": {
        "en" : "Gacha",
        "ja" : "ガチャ"
    },

    "price_coins": {
        "en" : "Price (Coins)",
        "ja" : "価格 (コイン)"
    },

    "is_vip": {
        "en" : "VIP only?",
        "ja" : "VIPのみ?"
    },

    "is_active": {
        "en" : "Enabled (Public)?",
        "ja" : "有効（公開）?"
    },

    "rarities": {
        "en" : "Rarities",
        "ja" : "封入率"
    },

    "rarity": {
        "en" : "Rarity",
        "ja" : "封入率"
    },

    "percent": {
        "en" : "Random%",
        "ja" : "封入率%"
    },

    "sparkle": {
        "en" : "Sparkle color",
        "ja" : "玉のキラキラ色"
    },

    "add": {
        "en" : "Add",
        "ja" : "追加"
    },

    "delete": {
        "en" : "Delete",
        "ja" : "削除"
    },

    "prizes": {
        "en" : "Prizes",
        "ja" : "賞品"
    },

    "is_active_true": {
        "en" : "Active",
        "ja" : "公開中"
    },

    "is_active_false": {
        "en" : "Hidden",
        "ja" : "非公開"
    },

    "button-replace": {
        "en" : "Replace",
        "ja" : "置き換える"
    },

    "button-send": {
        "en" : "Send",
        "ja" : "送信"
    },

    "title": {
        "en" : "Title",
        "ja" : "タイトル"
    },

    "wins": {
        "en" : "Winners",
        "ja" : "受賞者"
    },

    "image": {
        "en" : "Image File",
        "ja" : "画像ファイル"
    },

    "warning-edit-prize": {
        "en" : "This prize has already been won by customers. If you edit this prize, this will also edit the ones already delivered to users. If you want to change the prize to something else, set this one to Inactive and create a new one.",
        "ja" : "この賞品はすでに顧客に獲得されています。この賞品を編集すると、すでに配布されたものも編集されます。別の賞品に変更したい場合は、この賞品を非公開にして新しい賞品を作成してください。"
    },

    "warning-edit-prize_category": {
        "en" : "This gacha has already been played by customers. If you edit gacha prize, this will also edit the ones already delivered to users. If you want to change the gacha to something else, set this one to Inactive and create a new one.",
        "ja" : "このガチャはすでに顧客に獲得されています。このガチャを編集すると、すでに配布されたものも編集されます。別のガチャに変更したい場合は、このガチャを非公開にして新しい賞品を作成してください。"
    },

    "rarities-color-help": {
        "en" : "Special color codes: Silver 70706F, Gold DBB400, Rainbow EA00D3.",
        "ja" : "特別な色コード: シルバー 70706F, ゴールド DBB400, 虹 EA00D3."
    },

    "rarities-color-picker": {
        "en" : "Find other colors here.",
        "ja" : "他の色はこちらで見つけてください。"
    },

    "button-choose-file": {
        "en" : "Choose file",
        "ja" : "ファイルを選択"
    },

    "button-delete": {
        "en" : "Delete",
        "ja" : "削除"
    },



    "aaaa": {
        "en" : "eeeeee",
        "ja" : "jjjjj"
    },
})