import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, NavLink,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import { fetch_user } from "../../server";
import {t} from "../../localization";
import TabBar from "../tabBar";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}



export default function PublicMissions() {
    let allMissions = appState.missions.filter(mission => mission.is_active );

    let missions = window.location.pathname == '/missions' ? allMissions.filter(mission => mission.points == 1 ) : allMissions.filter(mission => mission.points > 1 );

    return (
        <>
            <TabBar className="missionListTabs" isExact={true} tabs={[
                { url: "/missions", name: t("tab-missions"), className: "" },
                { url: "/missions/special", name: t("tab-specialMissions"), className: "" },
            ]} />

            <div id="publicMissionList">
                { missions.map( (mission) => (
                    <div className="mission" key={"mission"+mission.id}>
                        <div className="info">
                            <div className="number">{ mission.number }</div>
                            { mission.limited_shopid ? <div className={"shop shop" + mission.limited_shopid}>{appState.shopLookup.get(mission.limited_shopid).name}</div> : "" }
                            { !mission.is_single_use ? <div className="multipleTimes">∞</div> : "" }
                            { mission.points > 1 ? <div className="points">{mission.points}p</div> : "" }
                            { appState.user != null ?
                            <div className="stamps">
                                { appState.user.stamps.filter( stamp => stamp.mission_id == mission.id).map( stamp => (
                                    <span className="stamp" key={"stamp"+stamp.id}>{appState.shopLookup.get(stamp.shop_id).initial}</span>
                                ) ) }
                            </div>
                                : "" }
                        </div>
                        <div className="name">{ mission.name }</div>
                    </div>
                ))}
            </div>
        </>
    );
}
