import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
} from "react-router-dom";
import { appState } from "../../appState";
import {server} from "../../server";
import {t, format_ts, format_ts_admin} from "../../localization";

export async function loader({ request }) {
    try {
        let cards = await server.admin_get_felica_cards()
        return { cards };
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}


export default function AdminFelicaCards() {
    const cards = useLoaderData().cards;

    const navigate = useNavigate();
    const handleRowClick = (card) => {
        navigate(`/admin/users/${card.user.id}`);
    }

    return (
        <>
            <section>
                <div className="cardsList">
                    <table>
                        <thead>
                        <tr>
                            <td>{t("user")}</td>
                            <td>{t("serial")}</td>
                            <td>{t("created_at")}</td>
                        </tr>
                        </thead>
                        <tbody>
                        { cards.map( card => (
                            <tr key={card.id}  onClick={()=> handleRowClick(card)}>
                                <td>{card.user.display_name}</td>
                                <td>{card.serial}</td>
                                <td>{format_ts(card.created_at, false, true)}</td>
                            </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
