import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
} from "react-router-dom";
import { appState } from "../../../appState";
import { fetch_user } from "../../../server";
import QRCode from "react-qr-code";

export async function loader({ request }) {
    return {  };
}


export default function CustomerGachaGallery() {
    const wins = appState.user.wins.slice().reverse();

    return (
        <>
            <section className="gallery">
                {
                    wins.map( (win) => (
                        <div className="win" key={win.id}>
                            <a href={"/gacha/wins/" + win.id}>
                                <img src={win.prize.reference.replace("gacha-images", "gacha-images-web-thumb")} />
                            </a>
                        </div>
                    ))
                }
            </section>
        </>
    );
}
