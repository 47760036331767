import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, Outlet,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import {fetch_user, server} from "../../server";
import TabBar from "../tabBar";
import {format_ts, format_ts_admin, t} from "../../localization";

export async function loader({ request }) {
    try {
        await server.fetch_common()
        let transactions_data = await server.admin_get_transactions();
        console.log(transactions_data);
        return { transactions_data };
    } catch (e) {
        alert(t(e));
    }

    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}


export default function AdminGachaHistory() {
    const { transactions_data } = useLoaderData();

    console.log(transactions_data);

    const transactions = [...transactions_data.transactions].reverse();

    return (
        <>
            <section>
                <h2>{t("gacha-transactions")}</h2>
                <table style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>{t("created_at")}</th>
                        <th>{t("name")}</th>
                        <th>{t("type")}</th>
                        <th>{t("coins")}</th>
                        <th>{t("prize")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactions.filter( (transaction) => transaction.amount_transaction == null && transaction.transaction_type != "free_stamp" ).map( (transaction) => (
                        <tr key={transaction.id}>
                            <td>{format_ts_admin(transaction.created_at, true)}</td>
                            <td>{transaction.user_display_name}</td>
                            <td>{t(transaction.transaction_type)}</td>
                            <td>{transaction.amount_coins}</td>
                            <td style={{textAlign: "right"}}>{transaction.win == null ? "" : transaction.win.prize.rarity.name} <br /> {transaction.win == null ? "" : transaction.win.prize.title}</td>
                        </tr>
                    ) )}
                    </tbody>
                </table>

            </section>
        </>
    );
}
