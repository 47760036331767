import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
} from "react-router-dom";
import { appState } from "../../appState";
import { fetch_user } from "../../server";
import {format_ts, t} from "../../localization";

export async function loader({ request }) {
    return {  };
}


export default function CustomerRallyCards() {
    let rallyCards = appState.user.rally_cards;

    rallyCards.reverse();

    return (
        <>
            <section className={"rallyCards"}>
                <div className="info">
                    <div className="title">{t("tab-stamprally")}</div>
                </div>
                <div className="rallyCardsList">
                    { rallyCards.map( (rallyCard) => (

                        <div className={"card " + (rallyCard.is_used ? "used" : "") + " " + (rallyCard.is_full || rallyCard.rally.is_valid ? "" : "invalid") } key={rallyCard.id}>
                            <div className="name">{rallyCard.rally.name}</div>
                            <div className="description">{rallyCard.rally.description}</div>
                            <div className="stamps">
                                { appState.shops.map( (shop) => (
                                    <div className={"stampHole shop"+shop.id } key={shop.id}>
                                        <div className="dotted"></div>
                                        { stampForShop(rallyCard, shop) != undefined ?
                                            <div className={"stamp shop" + shop.id} style={stampRand()}>
                                                <span className="shop">{shop.initial}</span>
                                                <span className="date">{stampForShop(rallyCard, shop).display_date}</span>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                    )) }
                            </div>
                            <div className="validity">{t("validity-period")} {format_ts(rallyCard.rally.valid_start)} 〜 {format_ts(rallyCard.rally.valid_end)}</div>
                        </div>
                        )) }
                </div>
            </section>
        </>
    );
}

function stampForShop(card, shop) {
    console.log(card, shop);
    return card.stamps.find(item => (item.stamp.shop_id == shop.id));
}

function stampRand() {
    const maxOffset = 8;
    const top = 4 + maxOffset - Math.random() * maxOffset*2
    const left = 4 + maxOffset - Math.random() * maxOffset*2
    const angle = 45 - Math.random() * 90

    return {top: top+"%", left: left+"%", transform: "rotate("+angle+"deg)"}
}
