import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
} from "react-router-dom";
import { appState } from "../../appState";
import {server} from "../../server";
import {t, format_ts, format_ts_admin} from "../../localization";

export async function loader({ request }) {
    try {
        let rally_cards = await server.admin_get_rally_cards()
        return { rally_cards };
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}


export default function AdminRallyCards() {
    const rally_cards = useLoaderData().rally_cards;

    const navigate = useNavigate();
    const handleRowClick = (card) => {
        navigate(`/admin/users/${card.user.id}`);
    }

    return (
        <>
            <section>
                <div className="rallyCardsList">
                    <table>
                        <thead>
                        <tr>
                            <td>{t("user")}</td>
                            <td>{t("rally")}</td>
                            <td>{t("stamps")}</td>
                            <td>{t("is_full")}</td>
                            <td>{t("is_used")}</td>
                        </tr>
                        </thead>
                        <tbody>
                        { rally_cards.map( rally_card => (
                            <tr key={rally_card.id} className={(rally_card.rally.is_valid ? "rally_valid" : "rally_invalid") + " " + (rally_card.is_used ? "card_used" : "card_notused") + " " + (rally_card.is_full ? "card_full" : "card_notfull")}  onClick={()=> handleRowClick(rally_card)}>
                                <td>{rally_card.user.display_name}</td>
                                <td>{rally_card.rally.name}</td>
                                <td>{rally_card.stamps.length}</td>
                                <td>{rally_card.is_full ? "○" : "×"}</td>
                                <td>{rally_card.is_used ? "○" : "×"}</td>
                            </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
