import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
} from "react-router-dom";
import { appState } from "../../appState";
import { t } from  "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "new") {
        return redirect(`/admin/missions/new/edit`);
    }

    return null
}


export default function AdminMissions() {
    const navigate = useNavigate();
    const handleRowClick = (mission) => {
        navigate(`/admin/missions/${mission.id}`);
    }

    return (
        <>
            <section>
            <Form method="post">
                <input type="hidden" name="action" value="new" />
                <button type="submit">{t("button-new")}</button>
            </Form>
            </section>

            <section>
                <div className="missionList">
                    <table>
                        <thead>
                        <tr>
                            <td>#</td>
                            <td>{t("name")}</td>
                            <td>{t("points-abbrev")}</td>
                            <td>{t("singleUse")}?</td>
                            <td>{t("shop")}</td>
                            <td>{t("active")}?</td>
                        </tr>
                        </thead>
                        <tbody>
                        { appState.missions.map( mission => (
                            <tr key={mission.id}  onClick={()=> handleRowClick(mission)}>
                                <td>{mission.number}</td>
                                <td>{mission.name}</td>
                                <td>{mission.points}</td>
                                <td>{mission.is_single_use ? "○" : "×"}</td>
                                <td>{appState.shopLookup.get(mission.limited_shopid) ? appState.shopLookup.get(mission.limited_shopid).initial : mission.limited_shopid}</td>
                                <td>{mission.is_active ? "○" : "×"}</td>
                            </tr>
                        )) }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}
