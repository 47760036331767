import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, Outlet,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import { fetch_user } from "../../server";
import TabBar from "../tabBar";
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);


    return null
}


export default function AdminNotifications() {
    return (
        <>
            <section>
                <h1>{t("tab-notifications")}</h1>
                <p>//TODO</p>
            </section>
        </>
    );
}
