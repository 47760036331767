import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit,
    useNavigate, redirect,
    useActionData,
} from "react-router-dom";
import {useState, useEffect} from "react";
import { appState } from "../../appState";
import {server} from "../../server";
import {t, format_ts, format_ts_admin, utcToLocal} from "../../localization";
import StyledFileUpload from "../../components/StyledFileUpload.tsx";

export async function loader({ params }) {
    try {
        let prize_categories = await server.admin_get_prize_categories()
        let prize_category_id = parseInt(params.prizeCategoryId);
        let prize_category = prize_categories.find(prize_category => prize_category.id === prize_category_id)

        if (prize_category == undefined) {
            prize_category = {
                id: "new",
            }
        }

        let prize_id = parseInt(params.prizeId);
        let prize = prize_category.prizes.find(prize => prize.id === prize_id)

        if (prize == undefined) {
            prize = {
                id: "new",
                title: "",
                rarity: {}
            }
        }

        return { prize_category, prize };
    } catch (e) {
        alert(t(e));
    }
    return null;
}

export const action = async ({ request, params }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);
    let prize_category_id = parseInt(params.prizeCategoryId);
    let prize_id = params.prizeId;

    if (formEntries.action == "save") {
        let prize_info = {
            title: formEntries.title,
            rarity_id: parseInt(formEntries.rarity_id),
            is_active: formEntries.is_active == 1,
            prize_type: "image",
        };

        if (prize_id == "new") {
            try {
                const image_file = formData.get("image_file");
                if (image_file != undefined) {
                    const result = await server.admin_new_prize_with_image(prize_category_id, prize_info, image_file);
                    return redirect(`/admin/prize_categories/`+ prize_category_id + "/prizes/" + result.id);
                } else {
                    const result = await server.admin_new_prize(prize_category_id, prize_info);
                    return redirect(`/admin/prize_categories/`+ prize_category_id + "/prizes/" + result.id);
                }
            } catch (e) {
                console.log(e);
                alert(t(e));
            }

        } else {
            try {
                const result = await server.admin_edit_prize(prize_category_id, prize_id, prize_info);
                console.log(result);
            } catch (e) {
                alert(t(e));
            }
        }
    } else if (formEntries.action == "delete") {
        try {
            const result = await server.admin_delete_prize(prize_category_id, prize_id);
            console.log(result);
        } catch (e) {
            alert(t(e));
        }

    } else if (formEntries.action == "replace_image") {
        try {
            const image_file = formData.get("image_file");
            const result = await server.admin_prize_image(prize_category_id, prize_id, image_file);
            console.log(result);
            return { success: true };
        } catch (e) {
            alert(t(e));
        }
    }

    return null
}


export default function AdminPrize() {
    const { prize_category, prize } = useLoaderData();
    const [ isEditingDetails, setIsEditingDetails ] = useState(prize.id == "new");
    const [ isEditingImage, setIsEditingImage ] = useState(false);
    const actionData = useActionData();

    const navigate = useNavigate();

    const confirmDelete = (event) => {
        const userConfirmed = window.confirm(t("confirm-delete"));
        if (!userConfirmed) {
            event.preventDefault(); // Prevent default form submission
        }
    };

    useEffect(() => {
        console.log("useEffect", actionData);
        if (actionData?.success) {
          setIsEditingImage(false);
        }
      }, [actionData]);
    
    return (
        <>
            <section>
                <div className="prizeEdit">
                    { prize.id == "new" ? "" :
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                navigate("/admin/prize_categories/"+prize_category.id);
                            }}
                        >
                            {t("button-back")}
                        </button>
                    </div>
                    }
                    <h1>{prize.title ?? prize.id}</h1>
                    {
                        prize.win_count > 0 ? <p className={"warning"}>{t("warning-edit-prize").split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}</p> : ""
                    }

                    { isEditingDetails ?
                        <Form method="POST" className="mobilecollapse" encType="multipart/form-data" onSubmit={() => {
                            setIsEditingDetails(false);
                        }}>
                            <input type="hidden" name="action" value="save" />
                            <table>
                                <tbody>
                                <tr>
                                    <td><label>ID</label></td>
                                    <td><span>{prize.id}</span></td>
                                </tr>
                                <tr>
                                    <td><label>{t("title")}</label></td>
                                    <td><span><input type="text" name="title" defaultValue={prize.title} /></span></td>
                                </tr>
                                <tr>
                                    <td><label>{t("rarity")}</label></td>
                                    <td>
                                        <select name="rarity_id" defaultValue={prize.rarity.id}>
                                            { prize_category.rarities.map( rarity => (
                                                <option key={rarity.id} value={rarity.id}>{rarity.name}</option>
                                            ) ) }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td><label>{t("is_active")}</label></td>
                                    <td><span><input type="checkbox" name="is_active" value={1} defaultChecked={prize.is_active} /></span></td>
                                </tr>
                                { prize.id != "new" ? "" :
                                    <tr>
                                        <td>{t("image")}</td>
                                        <td><StyledFileUpload name="image_file" /></td>
                                    </tr>
                                }
                                <tr>
                                    <td></td>
                                    <td>
                                        <div><button type="submit">{t("button-save")}</button></div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (prize.id == "new") {
                                                        navigate("/admin/prize_categories/"+prize_category.id);
                                                    } else {
                                                        setIsEditingDetails(false);
                                                    }
                                                }}
                                            >
                                                {t("button-cancel")}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Form>
                        :
                        <table>
                            <tbody>
                            <tr>
                                <td><label>ID</label></td>
                                <td>{prize.id}</td>
                            </tr>
                            <tr>
                                <td><label>{t("title")}</label></td>
                                <td>{prize.title}</td>
                            </tr>
                            <tr>
                                <td><label>{t("rarity")}</label></td>
                                <td>{prize.rarity.name}</td>
                            </tr>
                            <tr>
                                <td><label>{t("is_active")}</label></td>
                                <td>{prize.is_active ? "○" : "×"}</td>
                            </tr>
                            <tr>
                                <td><label>{t("wins")}</label></td>
                                <td>{prize.win_count}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setIsEditingDetails(true);
                                            }}
                                        >
                                            {t("button-edit")}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            { prize.win_count == 0 && !prize.is_active ?
                                <tr>
                                <td></td>
                                <td>
                                    <div>
                                        <Form method="POST" onSubmit={confirmDelete}>
                                            <input type="hidden" name="action" value="delete" />
                                            <button type="submit" >
                                                {t("button-delete")}
                                            </button>
                                        </Form>
                                    </div>
                                </td>
                            </tr> : "" }
                            </tbody>
                        </table>
                    }
                </div>
            </section>

            { prize.id == "new" ? "" :
                <section className="prizeEditImage">
                    {
                        prize.win_count > 0 ? <p className={"warning"}>{t("warning-edit-prize").split('\n').map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })}</p> : ""
                    }

                    <div>
                        { isEditingImage ?
                        <Form method="POST" encType="multipart/form-data">
                            <input type="hidden" name="action" value="replace_image" />
                            <StyledFileUpload name="image_file" /><br />
                        <button
                            type="submit"
                        >
                            {t("button-send")}
                        </button><br />
                            <button
                                type="button"
                                onClick={() => {
                                        setIsEditingImage(false);
                                }}
                            >
                                {t("button-cancel")}
                            </button>
                        </Form>
                            :
                            <div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsEditingImage(true);
                                    }}
                                >
                                    {t("button-replace")}
                                </button>
                            </div>
                        }
                    </div>

                    { isEditingImage ?  "" :
                    <div className="image">
                        <img src={prize.reference} />
                    </div>
                    }
                </section>
            }
        </>
    );
}
