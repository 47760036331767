import {
    Form,
    useLoaderData,
    useFetcher,
    useSubmit, redirect, Outlet, Navigate,
} from "react-router-dom";
import {appState, logout} from "../../appState";
import { fetch_user } from "../../server";
import AccountHome from "../account/account";
import {t} from "../../localization";

export async function loader({ request }) {
    return {  };
}

export const action = async ({ request }) => {
    const formData = await request.formData();
    const formEntries = Object.fromEntries(formData);

    if (formEntries.action == "logout") {
        await logout();
        return redirect(`/`);
    }

    return null
}


export default function StaffHome() {
    return (
        <>
            {window.location.pathname == "/" && (
                <Navigate to="/account" replace={true} />
            )}

            <section id="staffHome">
                <h2> {t("staff-home")}</h2>
            </section>

            <Outlet />
        </>
    );
}
